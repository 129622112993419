import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CameraNamesList',
  props: {
    fullList: {
      type: Boolean,
      default: true
    },

    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    nameList() {
      if (!this.fullList) {
        return this.list.slice(0, 10)
      }
      return this.list
    }
  },
  methods: {
    shortName(name, limit = 30) {
      if (name.length > limit) {
        return `${name.slice(0, limit)}...`
      }
      return name
    }
  }
})