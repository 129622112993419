import { defineComponent, onMounted, onBeforeUnmount, watch, computed } from '@vue/composition-api'
import { useDropdown, useFetchByScroll } from '@/components/composition/dropdown'
import { useFormatDate } from '@/components/composition/format-utils'
import { expoitationStatusList } from '@/consts/statuses'



export default defineComponent({
  name: 'NotVerifiedList',
  setup(props, { root } ) {
    const { dropdown, open, toggleOpen } = useDropdown()
    const remoteFn = (queryParams) => root.$store.dispatch('cameras/getNotVerifiedCameras', queryParams)    

    const {
      currentOptions,
      queryParamsSaved,
      fetchItems,
      resetResults,
      total,
      onScrollEnd
    } = useFetchByScroll( {
      remoteFn,
      open,
      isLoadMounted: false
    })

    const fetchUnverified = async () => {
      resetResults()
      await fetchItems(queryParamsSaved)
    }

    onMounted(() => {
      root.$eventBus.$on('bus:passport-change', fetchUnverified)
      fetchUnverified()
    })

    onBeforeUnmount(() => {
      root.$eventBus.$off('bus:passport-change', fetchUnverified)
    })
    
    const onGoToCameras = () => {
      const exploitationItemFilter = expoitationStatusList.find(item => item.id === 'notVerified')
      open.value = false
      root.$store.commit('cameras/SET_DEFAULT_FILTERS')
      root.$store.commit('cameras/SET_DEFAULT_PAGINATION')
      root.$store.commit('cameras/SET_FILTER', {
        key: 'status_exploitation', 
        value: {
          [exploitationItemFilter.id]: exploitationItemFilter
        }
      })

      if (root.$router.currentRoute.name === 'main') {
        root.$store.dispatch('cameras/getFilteredCamerasTable')
      } else {
        root.$router.push({
          name: 'main'
        })
      }
    }

    const updateLoading = computed(() => root.$store.state.update.loading)
    const wasError = computed(() => root.$store.state.update.wasError)

    watch(updateLoading, () => {
      if (!updateLoading.value && !wasError.value) {
        fetchUnverified()
      }
    })

    return {
      dropdown,
      open,
      toggleOpen,
      currentOptions,
      onScrollEnd,
      total,
      onGoToCameras,
      getStatusDate: useFormatDate(root.$moment)
    }
  }
})