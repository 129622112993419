import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import debounce from 'lodash.debounce'
import VTable from '@/components/table/table'
import LineScale from '@/components/ui/line-scale/line-scale.vue'
import CustomPagination from '@/components/ui/custom-pagination/custom-pagination.vue'
import { statisticTimeFormat } from '@/components/composition/format-utils'
import axios from 'axios'


export default defineComponent({
  name: 'TableReportCameras',
  components: {
    VTable,
    LineScale,
    CustomPagination
  },

  setup() {
    return {
      limit: 15,
      canceler: null,
      isInit: true
    }
  },

  data() {
    return {
      loading: false,

      /**
       * Объект вида {
       *  0: {
       *    name: "Cam with data 10000"
       *    percent: "100.0"
       *    value: "672:0"
       *    video_camera_id: 10000
       *  },
       *  pagination: {
       *    limit: 30,
       *    offset: 0,
       *    total: 100
       *  }
       * }
       */

      tableData: null,

      tableColumns: [
        {
          field: 'name',
          width: '380px',
        },
        {
          field: 'percent',
          thClass: 'th-hours'
        },
        {
          field: 'scale',
          thClass: 'th-scale',
          tdClass: 'td-scale'
        }
      ],

      search: '',

      sortParams: {
        nameSort: 'asc'
      }
    }
  },

  computed: {
    listData() {
      return this.tableData && this.tableData.list ? this.tableData.list : []
    },

    pageTotal() {
      if (this.tableData) {
        const { total, limit } = this.tableData.pagination
        const result = Math.ceil(total / limit)
        return result === 0 ? 1 : result
      }
      return 1
    },

    currentPage() {
      if (this.tableData) {
        const { offset, limit } = this.tableData.pagination
        const result = Math.ceil(offset / limit) + 1
        return result
      }
      return 1
    },

    filterParams() {
      return {
        q: this.search,
        ...this.sortParams
      }
    },

    getHoursCount() {
      let prevHoursText = ''
      let hoursMinutesObj = null
      return (hoursText) => {
        if (hoursText !== prevHoursText) {
          prevHoursText = hoursText
          hoursMinutesObj = statisticTimeFormat(hoursText)
        }
        return hoursMinutesObj
      }
    },
  },
  mounted() {
    /**
     * По хорошему переделать на кастомную таблицу,
     * но пришлось прибегнуть к такому костылю, так как
     * шкала должна находиться в отдельной ячейке, а писать логику серверной 
     * таблицы было бы оверхед
     */
    const thHours = this.$refs['table'].$el.querySelector('.th-hours')
    thHours.setAttribute('colspan', '2')
  },
  methods: {
    ...mapActions({
      getDetailCameraList: 'reports/getDetailCameraList'
    }),

    async getData(params) {
      if (!params) {
        params = this.getSearchParams()
      }
      try {
        if (this.canceler) {
          this.canceler()
        }
        if (this.isInit) {
          this.loading = true
        }
        const { req, cancelToken } = await this.getDetailCameraList(params)
        this.canceler = cancelToken
        this.tableData = await req
        if (this.isInit) {
          this.loading = false
        }
      } catch(e) {
        if (e instanceof axios.Cancel) {
          console.log(e)
        } else {
          if (this.isInit) {
            this.loading = false
          }
        }
      } finally {
        this.isInit = false
      }
    },

    getSearchParams() {
      return {
        limit: this.limit,
        offset: 0,
        ...this.filterParams
      }
    },

    onSearch: debounce(function() {
      const sendParams = this.getSearchParams()
      this.getData(sendParams)
    }, 300),

    onClickPage(page) {
      const sendParams = {
        limit: this.limit,
        offset: (page - 1) * this.limit,
        ...this.filterParams
      }
      this.getData(sendParams)
    },

    onSortChange: debounce(function(event) {
      const {field, type} = event[0]
      this.sortParams = {
        [`${field}Sort`]: type
      }
      this.onClickPage(this.currentPage)
    }, 300),

    onRowClick({ row }) {
      this.$emit('select-camera', row)
    }
  }
})

