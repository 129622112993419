import { defineComponent, reactive, computed, onBeforeMount, watch } from '@vue/composition-api'
import Datepicker from '@/components/datepicker/datepicker.vue'
import { useI18n } from 'vue-i18n-composable/dist/index.js'
import { getFormatDatePattern } from '@/components/composition/format-utils.js'

export default defineComponent({
  name: 'DateTime',
  components: {
    Datepicker,
    getFormatDatePattern
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      // moment date
      type: Object,
      default: null
    },
    error: {
      type: String | Boolean,
      default: ''
    }
  },
  setup(props, context) {

    const i18n = useI18n()

    const formatData = {
      date: {
        format: getFormatDatePattern()
      },
      time: {
          format: 'HH:mm',
          mask: '99:99',
          placeholder: '—:—',
      }
    }

    const controls = reactive({
      date: null,
      time: ''
    })

    const isDayStateEnabled = computed(() => {
      return i18n.locale.value === 'en'
    })

    const setDefaultValues = () => {
      controls.date = props.value && props.value.isValid() ? props.value.toDate() : new Date()
      const momentDate = context.root.$moment(controls.date)
      controls.time = momentDate.format(formatData.time.format)
    }

    onBeforeMount(() => {
      setDefaultValues()
    })

    

    const sendDate = () => {
      const { date, time } = controls
      const timeDate = context.root.$moment(time, formatData.time.format, true)
      if (!timeDate.isValid()) {
        // invalid date
        context.emit('input', context.root.$moment(null))
      } else {
        const diff = timeDate.diff(timeDate.clone().startOf('day'))
        const dateMoment = context.root.$moment(date).startOf('day')
        dateMoment.add(diff)
        context.emit('input', dateMoment)
      }
    }

    watch(i18n.locale, () => {
      sendDate()
    })

    watch(() => props.value, () => {
      setDefaultValues()
    })

    return {
      controls,
      formatData,
      isDayStateEnabled,
      sendDate
    }
  }
})

