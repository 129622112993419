import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
import ReportsModule from '@/components/reports-module/reports-module.vue'


export default defineComponent({
  name: 'Reports',

  components: {
    ReportsModule 
  },
  
  computed: {
    ...mapState({
      updateLoading: state => state.update.loading
    }),
  },
  mounted() {
    if (this.updateLoading) {
      this.$router.push({
        name: 'main'
      })
      return
    }
  }
})