import { ref, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { pageSize } from '@/consts/table'
import axios from 'axios'

export function useDropdown() {
  const dropdown = ref(null)
  const open = ref(false)

  const toggleOpen = () => {
    open.value = !open.value
  }

  const clickOutside = e => {
    if (!dropdown.value.contains(e.target)) {
      open.value = false
    }
  }

  onMounted(() => {
    window.addEventListener('click', clickOutside)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('click', clickOutside)
  })

  return {
    dropdown,
    open,
    clickOutside,
    toggleOpen
  }
}

export const proccessedList = (currentList = [], listAdd, offset) => {
  if (listAdd) {
    if (offset === 0) {
      return listAdd
    } else {
      return [...currentList, ...listAdd]
    }
  } else if (offset === 0) {
    return []
  }
  return currentList
}

/**
 * 
 * refs - open, currentOptions,
 */
export function useFetchByScroll({
    remoteFn,
    isLoadMounted = true,
  }) {

  const currentOptions = ref([])

  const resultState = {
    isFetching: false,
    cancelToken: null
  }

  const total = ref(0)

  /**
   * +Могут быть другие параметры
   */
  const queryParamsSaved = {
    /**
     * пока жестко зададим limit,
     * если нужно будет - сделаем параметром
     */
    limit: pageSize,
    offset: 0,
  }

  /*END pagination state */
  /**
   * 
   * @param {*} queryParams = { limit, offset }
   */
  const fetchItems = async (queryParams) => {
    if (resultState.isFetching) {
      return
    }
    const params = { ...queryParams }
    if (!params.q) {
      delete params.q
    }
    /* { req, cancelToken } */
    const result = await remoteFn(params)
    resultState.cancelToken = result.cancelToken
    resultState.isFetching = true
    try {
      if (params.q) {
        queryParamsSaved.q = params.q
      } else {
        delete queryParamsSaved.q
      }
      const res = await result.req
      currentOptions.value = proccessedList(currentOptions.value, res.list, res.pagination.offset)
      total.value = res.pagination.total
      queryParamsSaved.limit = res.pagination.limit
      queryParamsSaved.offset = res.pagination.offset
      resultState.isFetching = false
    } catch(e) {
      if (!(e instanceof axios.Cancel)) {
        resultState.isFetching = false
      }
    }
  }

  const resetResults = () => {
    if (resultState.cancelToken) {
      resultState.cancelToken()
    }
    queryParamsSaved.offset = 0
    total.value = 0
    resultState.isFetching = false
  }

  onMounted(() => {
    if (remoteFn && isLoadMounted) {
      fetchItems(queryParamsSaved)
    }
  })

  const onScrollEnd = e => {
    const { target } = e
    /**
     * Если не дошло до конца, то ничего не делаем
     */
    if (target.offsetHeight + target.scrollTop < target.scrollHeight) {
      return
    }

    if (resultState.isFetching) {
      return
    }

    const nextParams = {...queryParamsSaved}
    let nextOffset = nextParams.offset + nextParams.limit
    if (nextOffset > total.value) {
      nextOffset = total.value
    }
    nextParams.offset = nextOffset
    fetchItems(nextParams)
  }

  return {
    currentOptions,
    resultState,
    queryParamsSaved,
    fetchItems,
    resetResults,
    total,
    onScrollEnd
  }
}