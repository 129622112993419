import { ref, computed } from '@vue/composition-api'
import { pageSize } from '@/consts/table'
import { getPagesCamsTotal } from '@/filter-cams-utils'

/**
 * 
 * @param {*} optionsItems - реактивное свойство
 */
export const useClientSelectPagination = (optionsItems) => {
  const currentPage = ref(1)

  const pagesTotal = computed(() => {
    return getPagesCamsTotal(optionsItems.value, pageSize)
  })

  const optionsByPage = computed(() => {
    const result = optionsItems.value.slice(0, currentPage.value * pageSize)
    return result
  })

  const onScrollOptionsEnd = () => {
    const nextPage = currentPage.value + 1
  
    if (nextPage <= pagesTotal.value) {
      currentPage.value = nextPage
    }
  }

  return {
    currentPage,
    optionsByPage,
    onScrollOptionsEnd
  }
}