import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  mounted () {
    this.renderChart(this.chartData, {
      cutoutPercentage: 35,
      hoverBorderWidth: 0,
      layout: {
        /**
         * для того, чтобы вместился тултип. есть другое решение - это кастомный HTML тултип
         * https://github.com/chartjs/Chart.js/issues/622
         */
        padding: {
          top: 100,
          bottom: 100
        }
      },
      legend: {
        display: false
      },
      onClick: this.handle,
      hover: {
        onHover: (point, event) => {
          if (event[0]) {
            this.$el.querySelector('canvas').style.cursor = 'pointer'
            this.$emit('mouseenter')
          } else {
            this.$emit('mouseout')
            this.$el.querySelector('canvas').style.cursor = 'default'
          }
        }
      }
    })
  },
  methods: {
    handle(point, event) {
      this.$emit('click', event[0])
    }
  }
}