import { createI18n } from 'vue-i18n-composable/dist/index.js'

const userLocale = window.navigator.language


export const startLocale = userLocale.indexOf('ru') !== -1 ? 'ru' : 'en'

export const i18n = createI18n({
  locale: '',
  pluralizationRules: {
    'ru': function(choice = 0) {
      return choice
    },
    'en': function(choice = 0) {
      return choice
    },
  }
})
