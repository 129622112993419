import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ConfirmModal',
  props: {
    isAlert: {
      type: Boolean,
      default: false
    },
    blocked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    apply() {
      this.$emit('apply')
      if (!this.blocked) {
        this.hide()
      }
    },
    cancel() {
      this.$emit('cancel')
      this.hide()
    },
    onClose() {
      this.$emit('close')
    }
  }
})