import http from '@/services/http'
import { updateTimeout } from '@/consts/timeouts'

const getDefaultState = () => {
  return {
    loading: true,
    /**
     * пока нигде не используется
     */
    wasError: false
  }
}

let timeoutJob = null

export default {
  namespaced: true,

  state: getDefaultState(),
  
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },

    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_WAS_ERROR(state, wasError) {
      state.wasError = wasError
    }
  },
  actions: {
    async checkJob({ dispatch, commit }) {
      await dispatch('cancelCheckJob')
      
      try {
        const res = await http.get('check/job')
        
        /**
         * Строгое сравнение, так как
         * если запрос запущен то false,
         * если выполнен true
         * если null приходит значит еще никто ничего не запускал
         */

        if (res.checkJob === false) {
          commit('SET_LOADING', true)
          timeoutJob = setTimeout(() => {
            dispatch('checkJob')
          }, updateTimeout)
        } else {
          commit('SET_LOADING', false)
        }
        
      } catch(e) {
        commit('SET_LOADING', false)
        throw e
      }
    },
    cancelCheckJob() {
      if (timeoutJob) {
        clearTimeout(timeoutJob)
        timeoutJob = null
      }
    },
    async updateCameras({ dispatch, commit }) {
      try {
        commit('SET_WAS_ERROR', false)
        commit('SET_LOADING', true)
        dispatch('cancelCheckJob')
        await http.get('cameras', {
          params: {
            updateCameras: true 
          }
        })
        await dispatch('cameras/getCamerasAll', null, { root: true })
        dispatch('checkJob')
      } catch(e) {
        commit('SET_WAS_ERROR', true)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
}