import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions, mapMutations } from 'vuex'
import VTable from '@/components/table/table'
import CustomPagination from '@/components/ui/custom-pagination/custom-pagination.vue'
import PassportModal from '@/components/modals/passport-modal/passport-modal.vue'
import Stub from '@/components/stub/stub.vue'
import { useFormatDate } from '@/components/composition/format-utils'
import { getPagesCamsTotal } from '@/filter-cams-utils'

export default defineComponent({
  name: 'CamerasTable',

  components: {
    VTable,
    CustomPagination,
    PassportModal,
    Stub
  },

  setup(props, context) {
    const tableColumns = [
      {
        field: 'select-row',
        thClass: 'td-select',
        tdClass: 'td-select',
        sortable: false
      },
      {
        field: 'name',
        tdClass: 'td-name',
        width: '350px'
      },
      {
        field: 'status'
      },
      {
        field: 'status_exploitation'
      },
      {
        field: 'ip'
      },
      {
        field: 'ip_server'
      }
    ]

    return {
      tableColumns,
      getStatusDate: useFormatDate(context.root.$moment)
    }
  },

  data() {
    return {
      cameraEdit: null,
      initSort: false
    }
  },

  computed: {
    ...mapState({
      tableLoading: state => state.cameras.tableLoading,
      camerasTableList: state => state.cameras.camerasTableList,
      camerasTablePageList: state => state.cameras.camerasTablePageList,
      sortParams: state => state.cameras.sortParams,
      updateLoading: state => state.update.loading,
      pagination: state => state.cameras.pagination,
      selectedCameraList: state => state.selectedCamerasTable.selectedCameraList
    }),

    isSelectedCurrentPage() {
      return !!this.camerasTablePageList.length && this.camerasTablePageList.every(item => {
        return this.selectedCameraList.cameras[item.id] !== undefined
      })
    },

    pageTotal() {
      return getPagesCamsTotal(this.camerasTableList)
    },
  },

  created() {
    this.resetSelectedCameraListAction()
  },

  beforeDestroy() {
    this.onClickPage(1)
  },

  methods: {
    ...mapActions({
      sortCamerasTable: 'cameras/sortCamerasTable',
      paginateCamerasTable: 'cameras/paginateCamerasTable',
      removeSingleCamera: 'cameras/removeSingleCamera',
      selectSeveralCamerasTable: 'selectedCamerasTable/selectSeveralCamerasTable',
      uncheckSeveralCamerasTable: 'selectedCamerasTable/uncheckSeveralCamerasTable',
      setIsSelectAllTable: 'selectedCamerasTable/setIsSelectAllTable',
      resetSelectedCameraListAction: 'selectedCamerasTable/resetSelectedCameraListAction'
    }),

    ...mapMutations({
      setSelectedCameraList: 'selectedCamerasTable/SET_SELECTED_CAMERA_LIST',
      addSelectedCamera: 'selectedCamerasTable/ADD_SELECTED_CAMERA',
      removeSelectedCamera: 'selectedCamerasTable/REMOVE_SELECTED_CAMERA'
    }),

    onSelectCamera(isSelected, camItem) {
      isSelected ? this.addSelectedCamera(camItem) : this.removeSelectedCamera(camItem)
      if (this.isSelectedCurrentPage) {
        this.setIsSelectAllTable()
      } else {
        this.setIsSelectAllTable(false)
      }
    },

    onSelectCurrentPage(isSelected) {
      if (isSelected) {
        this.selectSeveralCamerasTable({
          camerasTableList: this.camerasTablePageList,
          /**
           * будет вызываться this.setIsSelectAllTable() в итоге в src/store/index.js
           */
          needCheckIsSelectAll: true
        })
      } else {
        this.uncheckSeveralCamerasTable(this.camerasTablePageList)
        this.setIsSelectAllTable(false)
      }
    },

    /**
     * 
     * Запустится сразу при дефолтной сортировке 
     */
    onSortChange(event) {
      if (!this.initSort) {
        this.initSort = true
        return
      }

      const sortData = { ...event[0] }

      this.sortCamerasTable(sortData)
    },

    onClickPage(number) {
      this.paginateCamerasTable({
        ...this.pagination,
        page: number
      })
    },

    onRowClick(event) {
      const { target } = event.event
      if (!target.classList.contains('td-select')) {
        this.cameraEdit = event.row
        this.$nextTick(() => {
          this.$refs['passport-modal'].show()
        })
      }
    },

    onClosePassportModal() {
      this.cameraEdit = null
    },

    onErrorUpdateCamera(dataCam) {
      this.removeSingleCamera(dataCam)
    }
  },

  watch: {
    pageTotal() {
      if (this.pageTotal < this.pagination.page) {
        /**
         * переместиться на последнюю страницу
         */
        this.paginateCamerasTable({
          ...this.pagination,
          page: this.pagination.page
        })
      }
    }
  }
})
