import { render, staticRenderFns } from "./loader-modal.vue?vue&type=template&id=1c70a96e&scoped=true&"
import script from "./loader-modal.js?vue&type=script&lang=js&"
export * from "./loader-modal.js?vue&type=script&lang=js&"
import style0 from "./loader-modal.scss?vue&type=style&index=0&id=1c70a96e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c70a96e",
  null
  
)

export default component.exports