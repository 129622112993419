import { mapState, mapActions, mapMutations } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import SelectLocale from '@/components/select-locale/select-locale.vue'
import NotVerifiedList from '@/components/not-verified-list/not-verified-list.vue'

export default defineComponent({
  name: 'Header',
  components: {
    SelectLocale,
    NotVerifiedList
  },
  computed: {
    ...mapState({
      updateLoading: state => state.update.loading,
      user: state => state.users.user
    })
  },
  methods: {
    ...mapActions({
      logout: 'users/logout'
    }),
    ...mapMutations({
      setGlobalLoading: 'SET_GLOBAL_LOADING'
    }),
    async onExit() {
      this.setGlobalLoading(true)
      try {
        await this.logout()
        if (this.$route.name !== 'auth') {
          this.$router.push({ name: 'auth' })
        }
      } finally {
        this.setGlobalLoading(false)
      }
    }
  }
})