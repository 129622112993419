import { mapActions, mapMutations, mapState } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SelectLocale from '@/components/select-locale/select-locale.vue'
import { blurForm } from '@/components/composition/blur-elements'

export default defineComponent({
  name: 'Auth',
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectLocale,
  },
  data() {
    return {
      form: {
        login: '',
        password: ''
      }
    }
  },
  mounted() {
    if(this.user) {
      /**
       * Повлечет за собой обновление всего стора,
       * в app.js
       */
      this.setUser(null)
    }
  },
  computed: {
    ...mapState({
      serverError: state => state.serverError,
      user: state => state.users.user
    }),
    isShowServerError() {
      return this.serverError
        && this.serverError.data
        && !this.serverError.data.isDialog
        && this.serverError.data.message
    }
  },
  methods: {
    ...mapActions({
      login: 'users/login'
    }),
    ...mapMutations({
      setGlobalLoading: 'SET_GLOBAL_LOADING',
      setServerError: 'SET_SERVER_ERROR',
      setUser: 'users/SET_USER'
    }),
    onSubmit() {
      blurForm(this.$refs.authForm.$el)
      this.setServerError(null)
      this.$refs.authForm.validate().then(async result => {
        if (result) {
          this.setGlobalLoading(true)
          try {
            await this.login(this.form)
            this.setGlobalLoading(false)
            this.$router.push('/')
          } finally {
            this.setGlobalLoading(false)
          }
        }
      })
    },
    onChangeLanguage() {
      if (this.$refs.authForm.flags.validated) {
        this.$refs.authForm.validate()
      }
      if (this.isShowServerError) {
        this.onSubmit()
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.onChangeLanguage()
    }
  }
})