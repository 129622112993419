import moment from 'moment-timezone'
import { extend, configure } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { getIpObj, compareIp } from '@/filter-cams-utils'
import { i18n } from './i18n'

extend('required', required)

configure({
  defaultMessage: (field, values) => {
    if (field !== '{field}') {
      values._field_ = i18n.t(`fields.${field}`)
    }
    return i18n.t(`validation.${values._rule_}`, values)
  }
})



extend('valid_ip_range', {
  validate: rangeTxt => {
    const rangeTxtCorrect = rangeTxt.replace(/[ _]/g, '')
    const ipArrStr = rangeTxtCorrect.split('-')
    const ipArr = ipArrStr.map(ipStr => {
      return getIpObj(ipStr)
    })

    if (!ipArr[0] || !ipArr[1]) {
      return false
    }

    return compareIp(ipArr[0], ipArr[1], 'isLessThan') || compareIp(ipArr[0], ipArr[1], 'isEquals')

  }
})

extend('valid_date', {
  validate: momentDate => {
    return momentDate.isValid()
  }
})

extend('not_future_date', {
  validate: momentDate => {
    if (moment().diff(momentDate) < 0) {
      return false
    }
    return true
  }
})

extend('resolution', {
  validate: resolutionStr => {
    const [width, height] = resolutionStr
      .split(' : ')
      .map(item => +item.replace(/_/g, ''))
    
    return width && height
  }
})