import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Stub',
  props: {
    type: {
      type: String,
      default: 'startSearch' // emptySearch | noConnection | startSearch
    }
  },
  computed: {
    iconCmpStr() {
      const iconCmpStr = this.type
        .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
        .toLowerCase()
        .split(' ')
        .join('-') + '-stub'
      
      return `stubs/${iconCmpStr}`
    }
  },
  methods: {
    refreshPage() {
      location.reload()
    }
  }
})