import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseIcon',
  
  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
  },
})
