import { defineComponent } from '@vue/composition-api'
import { mapState, mapMutations, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { blurForm } from '@/components/composition/blur-elements'
import IpServerSelect from '../ip-server-select/ip-server-select.vue'
import { useClearAllFilters } from '../../composition/useClearAllFilters'
import { cameraStatusList, expoitationStatusList } from '@/consts/statuses'

export default defineComponent({
  name: 'TableDataFilters',
  components: {
    ValidationObserver,
    ValidationProvider,
    IpServerSelect
  },
  setup(props, context) {

    const { clearAllFilters } = useClearAllFilters(context.root.$store)

    return {
      cameraStatusList,
      expoitationStatusList,
      clearAllFilters
    }
  },

  computed: {
    ...mapState({
      filters: state => state.cameras.filters
    })
  },
  methods: {
    ...mapMutations({
      setFilter: 'cameras/SET_FILTER'
    }),

    ...mapActions({
      applyFilters: 'cameras/applyFilters',
    }),

    onFilterSubmit(e) {
      blurForm(e.target)
      this.$refs['filter-form'].validate().then(result => {
        if (result) {
          this.applyFilters()
        }
      })
    },

    onSearch(event) {
      this.setFilter({ key: 'q', value: event })
    },

    onClearSearch() {
      this.setFilter({ key: 'q', value: '' })
      this.applyFilters()
    },
  },
  watch: {
    '$i18n.locale'() {
      if (this.$refs['filter-form'].flags.validated) {
        this.$refs['filter-form'].validate()
      }
    }
  }
})
