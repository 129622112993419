var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-report-cameras"},[(_vm.loading)?_c('div',{staticClass:"loader-wrapper"},[_c('loader')],1):_vm._e(),_c('fix-fluid-col-grid',{scopedSlots:_vm._u([{key:"fix-part",fn:function(){return [_c('div',{staticClass:"table-report-cameras__header"},[_vm._v(" "+_vm._s(_vm.$t('detailTableReport.header'))+" ")]),_c('v-input',{staticClass:"table-report-cameras__search",attrs:{"clear":"","placeholder":_vm.$t('placeholders.cameraSearch')},on:{"input":_vm.onSearch},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('base-icon',{attrs:{"name":"ic-search"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"fluid-part",fn:function(){return [_c('div',{staticClass:"table-scrollbar-wrapper"},[_c('v-table',{ref:"table",attrs:{"rows":_vm.listData,"columns":_vm.tableColumns,"sort-options":{
            enabled: true,
            initialSortBy: {
              field: 'name',
              type: 'asc'
            }
          },"mode":"remote","styleClass":"vgt-table"},on:{"on-sort-change":_vm.onSortChange,"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-column",fn:function(ref){
          var column = ref.column;
return [(column.field === 'name')?[_vm._v(" "+_vm._s(_vm.$t('detailTableReport.name'))+" "),_c('div',{staticClass:"border-separate"})]:(column.field === 'percent')?[_vm._v(" "+_vm._s(_vm.$t('detailTableReport.percent'))+" ")]:_vm._e()]}},{key:"table-row",fn:function(ref){
          var column = ref.column;
          var row = ref.row;
return [(column.field === 'name')?_c('div',{staticClass:"cell-name",attrs:{"title":row.name}},[_c('div',{staticClass:"cell-name__icon"},[_c('base-icon',{attrs:{"name":"cameras/ic-cam-notPTZ"}})],1),_c('div',{staticClass:"cell-name__text"},[_vm._v(" "+_vm._s(row.name)+" ")])]):(column.field === 'percent')?_c('div',{staticClass:"cell-value"},[_c('div',{staticClass:"cell-value__text"},[_vm._v(" "+_vm._s(row.value && _vm.$t('chart.summaryTime', { hours: _vm.getHoursCount(row.value).hours, minutes: _vm.getHoursCount(row.value).minutes }))+" ")])]):(column.field === 'scale')?_c('div',{staticClass:"cell-value"},[_c('div',{staticClass:"cell-value__scale"},[_c('line-scale',{attrs:{"value":+row.percent}})],1)]):_vm._e()]}},{key:"emptystate",fn:function(){return [_c('div',{staticClass:"table-loading-text"},[_vm._v(" "+_vm._s(_vm.$t('table.emptyState'))+" ")])]},proxy:true}])}),_c('custom-pagination',{attrs:{"value":_vm.currentPage,"page-count":_vm.pageTotal,"click-handler":_vm.onClickPage}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }