import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import StatisticChart from '@/components/reports-module/components/statistic-chart/statistic-chart.vue'
import TableReportCameras from '@/components/table-report-cameras/table-report-cameras.vue'

export default defineComponent({
  name: 'ReportDetailCamerasModal',
  props: {
    fieldObj: {
      /**
       * Объект вида {
       *   field: 'workingTime',
       *   id: 10
       * 
       *  см. src/consts/report-charts.js
       * },
       */
       type: Object
    }
  },
  components: {
    StatisticChart,
    TableReportCameras
  },
  data() {
    return {
      dataChart: null,
      chartLoading: false,
      selectedCamera: null,
      tableCameraData: null,
      isShowTable: false,
    }
  },
  methods: {
    ...mapActions({
      updateDetailCameras: 'reports/updateDetailCameras',
      getChartCameraDetail: 'reports/getChartCameraDetail'
    }),

    async show () {
      await this.updateDetailCameras(this.fieldObj)
      this.isShowTable = true
      this.$refs['report-detail-cameras-modal'].show()
    },

    hide () {
      this.isShowTable = false
      this.dataChart = null
      this.$refs['report-detail-cameras-modal'].hide()
    },

    async onSelectCamera(event) {
      try {
        this.chartLoading = true
        this.dataChart = await this.getChartCameraDetail({
          fieldObj: this.fieldObj,
          detailCameraId: event.video_camera_id
        })
        this.selectedCamera = event
      } finally {
        this.chartLoading = false
      }
    },

    onBackHistory() {
      this.dataChart = null
      this.selectedCamera= null
    }
  }
})