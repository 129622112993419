import { mapActions, mapMutations, mapState } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import cloneDeep from 'lodash.clonedeep'
import { useFormatDate } from '@/components/composition/format-utils'
import PassportForm from '@/components/passport-form/passport-form.vue'
import ConfirmAlertModal from '@/components/modals/confirm-alert-modal/confirm-alert-modal.vue'
import { setResolutionStr } from '@/components/composition/format-utils'

export default defineComponent({
  name: 'PassportModal',
  props: {
    camera: {
      type: Object
    }
  },
  components: {
    PassportForm,
    ConfirmAlertModal,
  },
  setup(props, context) {
    return {
      getStatusDate: useFormatDate(context.root.$moment),
      setResolutionStr,
      passportObjEdit: null
    }
  },
  data() {
    return {
      cameraObj: null,
      cameraImg: '',
      resolutionStr: '-',
      isNewPassport: true,
      editEnabled: false,
      refreshLoading: false,
      imageLoading: false
    }
  },
  computed: {
    ...mapState({
      updateLoading: state => state.update.loading,
      wasError: state => state.update.wasError,
    }),
  },
  methods: {
    ...mapActions({
      createUpdatePassport: 'cameras/createUpdatePassport',
      inOutExploitation: 'cameras/inOutExploitation',
      updateCameraInfo: 'cameras/updateCameraInfo',
      getCameraImage: 'cameras/getCameraImage'
    }),
    ...mapMutations({
      setGlobalLoading: 'SET_GLOBAL_LOADING',
      setFiltersReports: 'reports/SET_FILTER_REPORTS',
      resetFitlersReports: 'reports/RESET_FILTERS_REPORTS'
    }),
    setCameraData(cameraData) {
      const result = cloneDeep(cameraData)
      return result
    },
    
    isNoData(field) {
      if (!this.cameraObj.passport) {
        return '-'
      }
      if (!this.cameraObj.passport[field]) {
        return '-'
      }
      return this.cameraObj.passport[field]
    },
    
    setStartData(cameraData) {
      this.cameraObj = this.setCameraData(cameraData)
      this.resolutionStr = this.setResolutionStr(this.cameraObj.passport)
      this.isNewPassport = !cameraData.passport
    },
    show () {
      this.$refs.modal.show()
      if (this.camera) {
        /**
         * Сразу подставляем значения и смотрим не пришли ли новые данные
         */
        this.setStartData(this.camera)
        this.updateInfo()
      }
    },
    hide () {
      if (!this.editEnabled) {
        this.$refs.modal.hide()
        return
      }
      const isChanged = this.$refs['passport-form'].checkChange()
      if (isChanged) {
        this.$refs['confirm-alert-modal'].show()
      } else {
        this.$refs.modal.hide()
      }
    },
    onClose() {
      this.editEnabled = false
      this.$emit('close')
    },
    onCloseWithoutSave() {
      this.cancelEdit()
      this.hide()
    },
    enabledEdit() {
      this.editEnabled = true
    },
    cancelEdit() {
      this.editEnabled = false
    },
    checkErrorsUpdate(error, errorsList = [404, 409, 410]) {
      return errorsList.indexOf(error?.response?.status) !== -1
    },
    onPassportSuccess(event) {
      const isChanged = this.$refs['passport-form'].checkChange()
      if (isChanged) {
        if (this.cameraObj.status_exploitation == 'introduced') {
          this.passportObjEdit = event
          this.$refs['confirm-alert-modal-save'].show()
          return
        }
        this.onSetPassport(event)
        return
      }
      this.editEnabled = false
    },
    async onPassportConfirm() {
      try {
        await this.onSetPassport(this.passportObjEdit)
      } finally {
        this.passportObjEdit = null
      }
    },
    async onSetPassport(passportObj) {
      
      const sendObj = { ...passportObj }

      if (!sendObj.fps) {
        sendObj.fps = 0
      }
      if (!sendObj.kbps) {
        sendObj.kbps = 0
      }
      this.setGlobalLoading(true)
      try {
        const res = await this.createUpdatePassport({
          data: {
            id: this.cameraObj.id,
            data: {
              ...sendObj
            }
          },
          isCreate: this.isNewPassport
        })
        /**
         * не все поля включены в ответ,
         * поэтому делаем объединение и новые поля перезапишутся
         */
        this.setStartData({ ...this.cameraObj, ...res })
        this.editEnabled = false
        this.$eventBus.$emit('bus:passport-change')
      }
      catch(e) {
        if (this.checkErrorsUpdate(e)) {
          this.invokeUpdateCamera(true)
        }
      } finally {
        this.setGlobalLoading(false)
      }
    },
    checkOnInOutExploitation() {
      if (this.cameraObj.status_exploitation == 'introduced')
          this.$refs['confirm-alert-modal-exploitation'].show()
        else {
          this.onInOutExploitation()
        }
    },
    async onInOutExploitation() {
      this.setGlobalLoading(true)
      try {
        const res = await this.inOutExploitation({
          id: this.cameraObj.id,
          status_exploitation: this.cameraObj.status_exploitation
        })
        this.setStartData({ ...this.cameraObj, ...res })
      } catch(e) {
        if (this.checkErrorsUpdate(e)) {
          this.invokeUpdateCamera(true)
        }
      } finally {
        this.setGlobalLoading(false)
      }
    },
    fetchImage() {
      this.imageLoading = true
      return this.getCameraImage(this.cameraObj.id)
      .then(image => {
        this.cameraImg = image
      })
      .finally(() => {
        this.imageLoading = false
      })
    },
    invokeUpdateCamera(isRefresh) {
      if (isRefresh) {
        this.refreshLoading = true
      } else {
        this.setGlobalLoading(true)
      }
      return this.updateCameraInfo(this.cameraObj.id).then(res => {
        this.setStartData(res)
        this.editEnabled = false
      })
      .catch(e => {
        if (this.checkErrorsUpdate(e)) {
          this.$emit('error-update', this.cameraObj)
          this.$refs.modal.hide()
          throw e
        }
      })
      .finally(() => {
        if (isRefresh) {
          this.refreshLoading = false
        } else {
          this.setGlobalLoading(false)
        }
      })
    },
    updateInfo(isRefresh) {
      this.fetchImage()
      this.invokeUpdateCamera(isRefresh).catch(() => {})
    },
    onGoToReports() {
      this.resetFitlersReports()
      this.setFiltersReports({ key: 'selectedCameras', value: { [this.cameraObj.id]: this.cameraObj  } })
      this.$refs.modal.hide()
      this.$router.push({
        name: 'reports'
      })
    }
  },
  watch: {
    updateLoading() {
      if (!this.updateLoading && !this.wasError && this.camera) {
        this.updateInfo(true)
      }
    }
  }
})