import Vue from 'vue'
import VueCompositionAPI, { createApp } from '@vue/composition-api'
import PortalVue from 'portal-vue'
import inputMask from './plugins/input-mask'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import App from './components/app/app.vue'
import router from './router'
import store from './store'
import './shared-components'
import './validate-settings'
import { startLocale, i18n } from './i18n'

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(PortalVue)
Vue.use(inputMask)
Vue.use(VueMoment, { moment })
Vue.prototype.$eventBus = new Vue()


moment.locale(startLocale)


export const app = createApp({
  router,
  i18n,
  store,
  render: h => h(App),
})


app.mount('#app')
