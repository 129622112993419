import { defineComponent, computed, ref } from '@vue/composition-api'
import { mapState } from 'vuex'
import { useI18n } from 'vue-i18n-composable/dist/index.js'
import BarChart from '../BarChart'
import {formatNumber, statisticTimeFormat, getFormatDatePattern } from '@/components/composition/format-utils'
import { useGetDateLabel } from '../composition/useGetDateLabel'


export default defineComponent({
  name: 'StatisticChart',
  props: {
    field: {
      type: String,
      required: true
    },
    dataChart: {
      /**
       * intervals: [], массив объектов { start: String, end: String, value: string }
       * totalValue: '0:0' - time или '0.0' - percent
       * timeUnit - по спеке https://www.chartjs.org/docs/latest/axes/cartesian/time.html
       */
      type: Object
    },
    titleTop: {
      type: String,
      default: ''
    },
    barBorderColor: {
      type: String,
      default: '#51ACFF'
    },
    barBackgroundColor: {
      type: String,
      default: 'rgba(81, 172, 255, .5)'
    },
    loading: {
      type: Boolean,
      default: false
    },
    backHistory: {
      type: Boolean,
      default: false
    },
    modalChart: {
      type: Boolean,
      default: false
    },

    notDetailChart: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BarChart
  },
  setup(props, context) {
    const chart = ref(null)

    const { getDateLabel } = useGetDateLabel()

    const i18n = useI18n()

    const notClickable =  computed(() => {
      return props.dataChart.timeUnit === 'hour' ||
      props.field === 'workingTimePercent' || props.modalChart
    })

    const type = computed(() => {
      return props.field === 'workingTimePercent' ? 'percent' : 'time'
    })

    const getValuePoint = point => {
      const chartObject = chart.value.$data._chart
      const dataset = chartObject.config.data.datasets[0].data
      const chartElements = chartObject.getElementsAtEventForMode(point, 'index', chartObject.options)
      const index = chartElements[0]._index
      const value = dataset[index].y
      return {
        value,
        index
      }
    }

    const handleHover = point => {
      const { value } = getValuePoint(point)
      if (notClickable.value || value === 0) {
        point.target.style.cursor = 'default'
        
        return
      } else {
        point.target.style.cursor = 'pointer'
      }
    }

    const handleChartClick = point => {
      const { value, index } = getValuePoint(point)
      if (!notClickable.value) {
        if (value !== 0) {
          const selectedInterval = props.dataChart.intervals[index]
          context.emit('set-interval', selectedInterval)
        }
      } else if (value !== 0) {
        context.emit('error-set-interval', point)
      }
    }

    const chartOptions = {
      tooltips: {
        enabled: true,
        intersect: false,
        callbacks: {
          title(tooltipItem, data) {
            const item = data.datasets[0].data[tooltipItem[0].index]
            if (type.value === 'time') {
              const { hours, minutes } = item
              return i18n.t('chart.tootip', { hours: formatNumber(hours), minutes })
            }
            return `${item.y}%`
          },
          label() {
            return ''
          }
        },
        backgroundColor: '#384667',
        titleFontSize: 14,
        titleFontStyle: 'normal',
        titleMarginBottom: 0
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            fontSize: 14,
            fontColor: '#98A2BD',
            source: 'data',
            callback: function(value, index) {
              const extreme = index === 0
              return getDateLabel(value, props.dataChart.timeUnit, extreme)
            }
          },
          gridLines: {
            zeroLineColor: '#FFFFFF',
            color: 'rgba(91,102,131, .2)'
          }
        }],
        yAxes: [{
          ticks: {
            fontSize: 14,
            fontColor: '#98A2BD',
            beginAtZero: true,
            callback: function(value) {
              if (type.value === 'time') {
                let intPart = Math.floor(value)
                const floatPart = value - intPart
                let minutes = Math.round(floatPart * 60)
                if (minutes === 60) {
                  intPart++
                  minutes = 0
                }
                return i18n.t('chart.tootip', { hours: formatNumber(intPart), minutes })
              }
              
              return formatNumber(value)
            }
          },
          gridLines: {
            zeroLineColor: '#FFFFFF',
            color: 'rgba(91,102,131, .2)'
          }
        }]
      },
      responsive: true,
      maintainAspectRatio: false,
      onClick: handleChartClick,
      onHover: handleHover,

      layout: {
        padding: {
          right: 40,
          left: 30
        }
      }
    }

    return {
      chart,
      type,
      chartOptions,
      formatNumber,
      statisticTimeFormat
    }
  },
  data() {
    return {
      chartAreaHeight: 0
    }
  },
  computed: {
    ...mapState({
      reportCharts: state => state.reports.reportCharts,
    }),
    /**
     * {
     *   start: string,
     *   end: string
     * }
     */
    chartInterval() {
      const history = this.reportCharts[this.field].history
      return history && history[history.length - 1]
    },
    titleDate() {
      if (this.chartInterval) {
        const format = getFormatDatePattern()
        const start = this.$moment(this.chartInterval.start).locale(this.$i18n.locale).format(format)
        const end = this.$moment(this.chartInterval.end).locale(this.$i18n.locale).format(format)
        return `${start} - ${end}`
      }
      return ''
    },
    dataChartMap() {
      const dataChartMap = this.dataChart.intervals.map(item => {
        const resultObj = {
          /**
           * здесь дата и форматирование ее должно быть в колбеке,
           * иначе могут быть одинаковые точки например 01.05.2020 - 01.05.2021
          */
          x: item.start,
          end: item.end
        }
        if (this.type === 'time') {
          const { hours, minutes } = this.statisticTimeFormat(item.value)
          resultObj.y = hours + (minutes / 60)
          resultObj.hours = hours
          resultObj.minutes = minutes
        } else {
          resultObj.y = +item.value
        }
        return resultObj
      })

      const datasets = [
        {
          data: dataChartMap,
          backgroundColor: this.barBackgroundColor,
          borderWidth: 2,
          borderColor: this.barBorderColor
        }
      ]


      return {
        datasets,
        labels: dataChartMap.map(item => item.x),
        borderColor: 'rgb(75, 192, 192)',
        lineTension: 0.1
      }
    },
    totalValueCmp() {
      if (this.type === 'time') {
        const result = this.statisticTimeFormat(this.dataChart.totalValue)
        result.hours = this.formatNumber(result.hours)
        return result
      }
      return this.dataChart.totalValue
    }
  },
  methods: {
    sendBack() {
      this.$emit('history-back')
    },
    sendByCameras() {
      this.$emit('by-cameras')
    },
    setChartAreaHeight(height) {
      this.chartAreaHeight = height
    },
  }
})