import http from '@/services/http'
import Vue from 'vue'
import Vuex from 'vuex'
import cameras from './cameras'
import update from './update'
import reports from './reports'
import selectedCamerasTable from './selected-cameras-table'
import users from './users'

Vue.use(Vuex)

const modules = {
  cameras,
  update,
  reports,
  selectedCamerasTable,
  users
}

const getDefaultState = () => {
  return {
    globalLoading: false,
    errorConnection: false,
    serverError: null
  }
}

const store =  new Vuex.Store({
  namespaced: true,

  modules,

  state: {
    globalLoading: false,
    localeLoading: true,
    errorConnection: false,
    serverError: null
  },
  mutations: {
    /**
     * 
     * @param string[] preservedFields 
     */
    RESET_STATE(state, preservedFields = []) {
      const defaultState = getDefaultState()
      preservedFields.forEach(field => {
        delete defaultState[field]
      })
      Object.assign(state, defaultState)
    },
    SET_GLOBAL_LOADING(state, globalLoading) {
      state.globalLoading = globalLoading
    },
    SET_LOCALE_LOADING(state, localeLoading) {
      state.localeLoading = localeLoading
    },
    SET_ERROR_CONNECTION(state, errorConnection) {
      state.errorConnection = errorConnection
    },
    SET_SERVER_ERROR(state, serverError) {
      state.serverError = serverError
    }
  },
  actions: {
    /**
     * 
     * @param string[] preservedFields - на модули НЕ действует, нужно для сохранения ошибок
     */
    resetGlobalState({ commit }, preservedFields = []) {
      commit('RESET_STATE', preservedFields)

      Object.keys(modules).forEach(moduleKey => {
        commit(`${moduleKey}/RESET_STATE`, null, { root: true })
      })
    },
    /**
     * @param 'ru'|'en' lang
     */
    changeLocale(store, lang) {
      return http.post('/locale', {
        lang
      })
    }
  }
})

export default store