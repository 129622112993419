import { defineComponent, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable/dist/index.js'
import { useDropdown } from '@/components/composition/dropdown'
import { useLoadLanguageAsync } from '@/components/composition/loading-translations'

export default defineComponent({
  name: 'SelectLocale',
  setup(props, context) {
    
    const { dropdown, open, toggleOpen } = useDropdown()

    const localeList = [
      {
        id: 'ru',
        name: 'РУС'
      },
      {
        id: 'en',
        name: 'ENG'
      }
    ]

    const i18n = useI18n()

    const activeLocale = ref(localeList.find(l => l.id === i18n.locale.value))

    const onChangeLocale = async (localeObj) => {
      await useLoadLanguageAsync(context.root.$store, localeObj.id)
      activeLocale.value = localeObj
      context.root.$moment.locale(localeObj.id)
      open.value = false
    }
    
    return {
      activeLocale,
      dropdown,
      open,
      toggleOpen,
      localeList,
      onChangeLocale,
      currentLocale: i18n.locale
    }
  }
})