var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header__left-part"},[_c('router-link',{staticClass:"header__logo",attrs:{"to":{
        name: 'main'
      }}},[_c('img',{attrs:{"src":require("@/assets/svg/Securos_SystemDashboard_Logo.svg"),"alt":"Logo"}})]),_c('router-link',{staticClass:"header__page-link header__page-link--main",attrs:{"to":{
        name: 'main'
      }}},[_vm._v(" "+_vm._s(_vm.$t('header.navigation.main'))+" ")]),_c('router-link',{staticClass:"header__page-link",class:{
        disabled: _vm.updateLoading
      },attrs:{"to":{
        name: 'reports'
      }}},[_vm._v(" "+_vm._s(_vm.$t('header.navigation.reports'))+" ")])],1),_c('div',{staticClass:"header__right-part"},[_c('select-locale'),_c('not-verified-list',{staticClass:"not-verified-list"}),(_vm.user)?[_c('div',{staticClass:"user-info",attrs:{"title":_vm.user.name}},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('button',{staticClass:"btn secondary exit-btn",attrs:{"type":"button"},on:{"click":_vm.onExit}},[_c('base-icon',{staticClass:"exit-btn__icon",attrs:{"name":"ic-exit"}}),_c('div',{staticClass:"exit-btn__text"},[_vm._v(" "+_vm._s(_vm.$t('buttons.exit'))+" ")])],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }