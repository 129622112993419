import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
import CameraNamesList from '@/components/camera-names-list/camera-names-list.vue'
import CameraNamesListWrapper from '@/components/camera-names-list-wrapper/camera-names-list-wrapper.vue'
import PassportForm from '@/components/passport-form/passport-form.vue'

export default defineComponent({
  name: 'GroupPassportModal',
  props: {
    startPassport: {
      type: Object
    }
  },
  components: {
    PassportForm,
    CameraNamesList,
    CameraNamesListWrapper
  },
  data() {
    return {
      fullList: false
    }
  },
  computed:{
    ...mapState({
      selectedCameraNameList: state => {
        return Object.values(state.selectedCamerasTable.selectedCameraList.cameras)
          .map(camItem => camItem.name)
      }
    }),
  },
  methods: {
    show () {
      this.$refs['modal-group'].show()
    },
    hide () {
      this.$refs['modal-group'].hide()
    },
    onClose() {
      this.$emit('close-group-modal')
    },
    onSubmit(event) {
      this.$emit('submit-group-modal', event)
    }
  }
})