import { computed } from '@vue/composition-api'

/**
 * 
 * @param {*} moment - объект moment.js - брать из context.root
 */

 export const getFormatDatePattern = (short = false) => {
  const monthPart = 'MM'
  const dayPart = 'DD'
  const yearPart = 'YYYY'
  let dateArr = [ dayPart, monthPart ]
  let delimeter = '.'
  if (navigator.language === 'en-US') {
    dateArr = [ monthPart, dayPart ]
    delimeter = '/'
  }
  if (!short) {
    dateArr.push(yearPart)
  }
  return dateArr.join(delimeter)
}

export const useFormatDate = moment => computed(() => date => {
    return moment(date).format(getFormatDatePattern())
})

export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

/**
 * 
 * @param {*} dateStr - строка вида часы:минуты
 */
export const statisticTimeFormat = (dateStr) => {
  if (dateStr) {
    const dateStrArr = dateStr.split(':')
    return {
      hours: +dateStrArr[0],
      minutes: +dateStrArr[1]
    }
  }
  return null
}

/**
 * 
 * @param {{ width: number; height: number }} passport 
 * @returns 
 */

 export const setResolutionStr = (passport) => {
  let width = 0
  let height = 0
  if (passport) {
    width = passport.width || 0
    height = passport.height || 0
  }
  if (width === 0 || height === 0) {
    return '-'
  }
  return `${width} : ${height}`
}

