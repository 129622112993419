import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    stopPropagation: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const onClick = event => {
      if (!props.disabled) {
        context.emit('input', !props.value)
      }
      if (props.stopPropagation) {
        event.stopPropagation()
      }
    }
    return {
      onClick
    }
  }
})