import TimeIntervalFormModal from '@/components/modals/time-interval-form-modal/time-interval-form-modal.vue'
import { defineComponent, ref, computed } from '@vue/composition-api'
import { getFormatDatePattern } from '@/components/composition/format-utils.js'

export default defineComponent({
  name: 'SelectInterval',
  components: {
    TimeIntervalFormModal
  },
  props: {
    value: {
      /**
       * { startDate, endDate - moment objects }
       */
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  setup(props, context) {
    const timeIntervalFormModal = ref(null)

    const showSelectedValueFunc = computed(() => value => {
      const dateFormat = `${getFormatDatePattern()} (HH:mm)`
      if (value) {
        const { startDate, endDate } = value
        return `${startDate.format(dateFormat)} - ${endDate.format(dateFormat)}`
      }
      return ''
    })

    const options = [
      {
        unitCount: 1,
        unitName: 'day',
        id: 1,
        name: 'selectsOptions.timeInterval.last_day'
      },
      {
        unitCount: 1,
        unitName: 'week',
        id: 2,
        name: 'selectsOptions.timeInterval.last_week'
      },
      {
        unitCount: 1,
        unitName: 'month',
        id: 3,
        name: 'selectsOptions.timeInterval.last_month'
      },
      {
        unitCount: 1,
        unitName: 'year',
        id: 4,
        name: 'selectsOptions.timeInterval.last_year'
      },
      {
        id: 0,
        name: 'selectsOptions.timeInterval.choose'
      }
    ]

    const onInput = option => {
      if (option) {
        if (option.id !== 0) {
          const startDate = context.root.$moment().subtract(option.unitCount, option.unitName)
          const endDate = context.root.$moment()
          context.emit('input', {
            value: option.id,
            startDate,
            endDate
          })
        } else {
          timeIntervalFormModal.value.show()
        }
      }
    }

    const setCustomTime = ({ startDate, endDate }) => {
      context.emit('input', {
        value: 0,
        startDate,
        endDate
      })
    }

    return {
      timeIntervalFormModal,
      showSelectedValueFunc,
      options,
      onInput,
      setCustomTime
    }
  }
})