import { defineComponent } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestrictedNumberInput from '@/components/ui/restricted-number-input/restricted-number-input.vue'
import ResolutionInput from '@/components/ui/resolution-input/resolution-input.vue'
import { setResolutionStr } from '@/components/composition/format-utils'
import { blurForm } from '@/components/composition/blur-elements'

const getDefaultForm = () => ({
  fps: '',
  kbps: '',
  width: 0,
  height: 0
})

export default defineComponent({
  name: 'PassportForm',
  props: {
    startData: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    RestrictedNumberInput,
    ResolutionInput
  },
  setup() {
    return {
      setResolutionStr,
      dataSetted: false
    }
  },
  data() {
    return {
      // поля могут быть как 0, так и ''
      form: getDefaultForm(),
      resolutionStrEdit: '-'
    }
  },
  computed: {
    isFiledRequired() {
      return !((this.form.width && this.form.height) || this.form.kbps || this.form.fps)
    },
    isFiledResolutionRequired() {
      if ((!this.form.width && this.form.height) || (this.form.width && !this.form.height)) {
        return true
      }
      return this.isFiledRequired
    },
    isPassportEmpty() {
      if (this.editEnabled && this.form) {
        return !((this.form.width && this.form.height) && !this.form.fps && !this.form.kbps)
      }
    }
  },
  mounted() {
    if (this.startData) {
      Object.keys(this.form).forEach(key => {
        if (this.startData[key]) {
          this.form[key] = this.startData[key]
        }
      })
    }
    this.resolutionStrEdit = setResolutionStr(this.form)
    this.$nextTick(() => {
      this.$refs.form.validate()
      this.dataSetted = true
    })
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(async success => {
        if (success) {
          blurForm(this.$refs['form'].$el)
          this.$emit('submit', this.form)
        }
      })
    },
    onCancel() {
      this.$emit('cancel')
    },
    onInputResolution(event) {
      const [ width, height ] = event.split(' : ')
      .map(item => item.replace(/_/g, ''))
      this.form.width = width ? width : 0
      this.form.height = height ? height : 0
    },
    /**
     * !!! для вызова метода из родительского компонента
     */
    checkChange() {
      let isChanged = false
      const pasportKeys = Object.keys(this.form)
      const defaultForm = getDefaultForm()
      for (let i = 0; i < pasportKeys.length; i++) {
        const key = pasportKeys[i]
        if (this.startData) {
          if (
            (!this.startData[key] && this.form[key]) ||
            (this.startData[key] && !this.form[key]) ||
            /**
             * !!! не строгое равенство здесь обязательно
             */
            this.startData[key] != this.form[key]
          ) {
            isChanged = true
            break
          }
        } else {
          /**
           * !!! не строгое равенство здесь обязательно
           */
          if (defaultForm[key] != this.form[key]) {
            isChanged = true
            break
          }
        }
      }
      return isChanged
    }
  }
})