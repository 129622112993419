var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistic-chart-wrapper",class:{
    'modal-chart': _vm.modalChart
  }},[(_vm.loading)?_c('loader',{staticClass:"loader"}):_vm._e(),_c('fix-fluid-col-grid',{staticClass:"statistic-chart-grid",scopedSlots:_vm._u([(_vm.dataChart)?{key:"fix-part",fn:function(){return [_c('div',{staticClass:"statistic-chart-header"},[(_vm.backHistory)?_c('button',{staticClass:"ic-back-btn",on:{"click":_vm.sendBack}},[_c('base-icon',{attrs:{"name":"ic-back-btn"}})],1):_vm._e(),_c('div',{staticClass:"statistic-chart-header__title"},[(_vm.titleTop)?_c('div',{staticClass:"statistic-chart-header__title--top"},[_vm._v(" "+_vm._s(_vm.titleTop)+" ")]):_vm._e(),(_vm.titleDate)?_c('div',{staticClass:"statistic-chart-header__title--date"},[_vm._v(" "+_vm._s(_vm.titleDate)+" ")]):_vm._e(),_c('div',{staticClass:"statistic-chart-header__title--text"},[_vm._v(" "+_vm._s(_vm.$t(("chart.titles." + _vm.field)))+" ")])]),_c('div',{staticClass:"statistic-chart-header__right-part"},[(!_vm.modalChart && !_vm.notDetailChart)?_c('button',{staticClass:"btn secondary btn-detail-report",on:{"click":_vm.sendByCameras}},[_c('base-icon',{staticClass:"btn-detail-report__icon",attrs:{"name":"ic-detail-report"}}),_c('div',{staticClass:"btn-detail-report__text"},[_vm._v(" "+_vm._s(_vm.$t('buttons.detailReport'))+" ")])],1):_vm._e(),_c('div',{staticClass:"statistic-chart-header__description"},[_c('div',{staticClass:"statistic-chart-header__description--time"},[_vm._v(" "+_vm._s(_vm.type === 'time' ? _vm.$t('chart.summaryTime', _vm.totalValueCmp) : (_vm.totalValueCmp + "%"))+" ")]),_c('div',{staticClass:"statistic-chart-header__description--text"},[_vm._v(" "+_vm._s(_vm.$t('chart.forAllTime'))+" ")])])])]),_c('div',{staticClass:"statistic-chart-label statistic-chart-label--y"},[_vm._v(" "+_vm._s(_vm.type === 'time' ? _vm.$t('chart.timeAxis') : _vm.$t('chart.percentAxis'))+" ")])]},proxy:true}:null,{key:"fluid-part",fn:function(){return [(_vm.dataChart)?_c('div',{staticClass:"statistic-chart"},[_c('div',{staticClass:"statistic-chart-label statistic-chart-label--x",style:({
            top: ((_vm.chartAreaHeight - 16) + "px")
          })},[_vm._v(" "+_vm._s(_vm.$t(("chart.timeUnits." + (_vm.dataChart.timeUnit))))+" ")]),_c('bar-chart',{ref:"chart",attrs:{"chart-data":_vm.dataChartMap,"options":_vm.chartOptions,"time-unit":_vm.dataChart.timeUnit,"styles":{
            height: '100%',
            position: 'relative'
          }},on:{"set-chart-area-height":_vm.setChartAreaHeight}})],1):_c('div',{staticClass:"empty-chart"},[_vm._v(" "+_vm._s(_vm.$t('chart.emptyChart'))+" ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }