export const useClearAllFilters = (store) => {

  const clearAllFilters = (savedFilters = []) => {
    store.commit('cameras/SET_DEFAULT_FILTERS', savedFilters)
    return store.dispatch('cameras/applyFilters')
  }

  return {
    clearAllFilters
  }
}