export const WorkerTypes = {
  CAMERAS_FILTER_START: 'CAMERAS_FILTER_START',
  CAMERAS_SELECT_SEVERAL_TABLE_START: 'CAMERAS_SELECT_SEVERAL_TABLE_START',
  CAMERAS_UNCHECK_ALL_TABLE_START: 'CAMERAS_UNCHECK_ALL_TABLE_START',
  CAMERAS_GET_IS_SELECT_ALL_START: 'CAMERAS_GET_IS_SELECT_ALL_START',
  CAMERAS_FIND_INDEX_START: 'CAMERAS_FIND_INDEX_START',
  CAMERAS_GET_CAMERAS_ALL_HASH_START: 'CAMERAS_GET_CAMERAS_ALL_HASH_START',

  REPORTS_GET_CAMERAS_OPTIONS_ALL_START: 'REPORTS_GET_CAMERAS_OPTIONS_ALL_START',
  REPORTS_GET_CAMERAS_OPTIONS_FILTERED_START: 'REPORTS_GET_CAMERAS_OPTIONS_FILTERED_START',
  REPORTS_CHECK_IS_SELECTED_ALL_START: 'REPORTS_CHECK_IS_SELECTED_ALL_START',
  REPORTS_SELECT_ALL_START: 'REPORTS_SELECT_ALL_START',
  REPORTS_UNCHECK_ALL_START: 'REPORTS_UNCHECK_ALL_START',

}