import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { i18n } from '@/i18n'

export const baseURL = '/api/v1/'

const http = axios.create({
  baseURL
})

const checkExceptionGlobalError = (response) => {
  if (
    response.config.url.startsWith('camera/passport') &&
    ['delete', 'patch'].indexOf(response.config.method) !== -1 &&
    response.status === 410
  ) {
    return true
  }

  return false
}

http.interceptors.response.use(r => {
  let data = null
  if (r.data.data) {
    data = r.data.data
  } else {
    data = r.data
  }
  if (data instanceof Blob) {
    return {
      data,
      filename: r.headers['content-disposition'].split('filename=')[1]
    }
  }
  return data
}, async e => {  
  if (e instanceof axios.Cancel) {
    return Promise.reject(e)
  }
  console.log('e.response', e.response)
  if (e.code === 'ECONNABORTED') {
    const serverError = {
      status: 500,
      data: {
        message: i18n.t('serverErrors.time_out_error')
      }
    }
    store.commit('SET_SERVER_ERROR', serverError)
  } else if (!e.response) {
    store.commit('SET_ERROR_CONNECTION', true)
  } else {
    if ( e.response.data instanceof Blob) {
      const errorText = await e.response.data.text()
      e.response.data = JSON.parse(errorText)
    }
    if (!checkExceptionGlobalError(e.response)) {
      store.commit('SET_SERVER_ERROR', e.response)
    }

    if (!e.response.data.isDialog) {
      if (router.currentRoute.name !== 'auth') {
        router.push({
          name: 'auth'
        })
      }
    }
  }
  return Promise.reject(e)
})


export default http
