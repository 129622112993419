import { defineComponent } from '@vue/composition-api'
import debounce from 'lodash.debounce'
import { useDropdown } from '@/components/composition/dropdown'

export default defineComponent({
  name: 'VSelect',

  props: {
    placeholder: {
      type: String
    },
    value: {
      /**
       * в случае если множественный селект,
       * то хеш таблица с { id (fieldId): { id(fieldId), name(fieldName) } }
       */
      type: Object
    },
    options: {
      type: Array,
      /**
       * массив объектов с ключевыми полями,
       * соответствующими props fieldValue и fieldName
       * или массив строк
       */
      default: () => []
    },

    /**
     * Нужен, если подсчет всех выделенных ведется
     * в вышестоящем компоненте
     */
    isCheckedAll: {
      type: Boolean | undefined
    },

    selectAllModel: {
      type: Boolean | undefined
    },

    isMultiple: {
      type: Boolean,
      default: false
    },
    
    fieldId: {
      type: String,
      default: 'id'
    },

    fieldName: {
      type: String,
      default: 'name'
    },

    searchModel: {
      type: String
    },

    searchable: {
      type: Boolean,
      default: false
    },

    heightOptions: {
      type: String,
      default: 'auto'
    },

    isTranslate: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    showSelectedValueFunc: {
      type: Function
    }
  },

  setup() {
    const { dropdown, open, toggleOpen } = useDropdown()
    return {
      dropdown,
      open,
      toggleOpen,
    }
  },

  data() {
    return {
      search: '',
    }
  },

  computed: {
    filteredOptions() {
      if (this.searchModel !== undefined) {
        /**
         * фильтрация опций вышестоящим компонентом
         */
        return this.options
      }
      return this.options.filter(option => {
        return option[this.fieldName].toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    },

    isSelectedAll() {
      if (this.selectAllModel !== undefined) {
        return this.selectAllModel
      }
      if (this.isMultiple) {
        return !!this.filteredOptions.length && this.filteredOptions.every(option => {
          return this.value[option[this.fieldId]] !== undefined
        })
      }
      return false
    },

    getOptionSelected() {
      return option => {
        if (this.isMultiple) {
          return this.value[option[this.fieldId]] !== undefined
        }
        return this.value && this.value[this.fieldId] === option[this.fieldId]
      }
    },

    valueName() {
      
      let needTranslate = false
      let name = ''
      if (this.showSelectedValueFunc) {
        name = this.showSelectedValueFunc(this.value)
      } else if (this.isMultiple) {
        const valueCount = Object.keys(this.value).length
        const optionsCount = this.options.length
        /**
         * Если фильтрация производится вышестоящим компонентом,
         * то и подсчет выделены ли ВСЕ должен производится вышестоящим компонентом и 
         * результат передаваться в пропу isCheckedAll
         */
        const isCheckedAll = this.isCheckedAll !== undefined ? this.isCheckedAll : 
        valueCount === 0 || (optionsCount > 0 && optionsCount === valueCount)
        if (isCheckedAll) {
          needTranslate = true
          name = 'selectsOptions.all'
        } else {
          if (valueCount === 1) {
            needTranslate = this.isTranslate
            name =  Object.values(this.value)[0][this.fieldName]
          }
  
          if (valueCount > 1) {
            name = valueCount
          }
        }
        
      } else if (this.value) {
        needTranslate = this.isTranslate
        name = this.value[this.fieldName]
      }

      return {
        needTranslate,
        name
      }
    },

    showClearIcon() {
      if (!this.clearable) {
        return false
      }
      if (this.isMultiple) {
        return Object.keys(this.value).length > 0
      }
      return !!this.value
    }
  },

  methods: {

    searchInput(event) {
      // this.resetResults()
      if (this.searchModel !== undefined) {
        this.$emit('update:search-model', event)
        return
      }
      this.search = event
    },

    onSearch: debounce(function (event) {
      this.searchInput(event)
    }, 300),

    getOptionName(option) {
      const name = option[this.fieldName]
      if (this.isTranslate) {
        /**
         * из плюрализации берется первый вариант,
         * если что - переиграть логику с доп. props
         */
        return this.$tc(name, 0)
      }
      return name
    },

    onSelectAll() {
      if (this.selectAllModel !== undefined) {
        this.$emit('update:select-all-model', !this.selectAllModel)
        return
      }

      const newValue = { ...this.value }

      if (!this.isSelectedAll) {
        this.filteredOptions.forEach(option => {
          newValue[option[this.fieldId]] = option
        })
      } else {
        this.filteredOptions.forEach(option => {
          delete newValue[option[this.fieldId]]
        })
      }
    
      this.$emit('input', newValue)
    },

    onSelectOption(option) {
      if (this.isMultiple) {
        const newValue = { ...this.value }
        if (newValue[option[this.fieldId]] === undefined) {
          newValue[option[this.fieldId]] = option
        } else {
          delete newValue[option[this.fieldId]]
        }
        
        this.$emit('input', newValue)
      } else {
        this.$emit('input', option)
        this.open = false
      }
    },

    onClear() {
      if (this.isMultiple) {
        this.$emit('input', {})
        return
      }
      this.$emit('input', null)
    },

    onScrollEnd(e) {
      const { target } = e
      if (target.offsetHeight + target.scrollTop < target.scrollHeight) {
        return
      }
      this.$emit('scroll-options-end')
    }
  }
})