import { defineComponent, computed } from '@vue/composition-api'
import debounce from 'lodash.debounce'
import { mapState, mapMutations, mapActions } from 'vuex'
import { useClientSelectPagination } from '@/components/composition/client-page-pagination'

export default defineComponent({
  name: 'ReportCamerasSelect',

  setup(props, context) {
    const camsOptionsFiltered = computed(() => {
      return context.root.$store.state.reports.camsOptionsFiltered
    })
    const {
      currentPage,
      optionsByPage,
      onScrollOptionsEnd
    } = useClientSelectPagination(camsOptionsFiltered)
    return {
      camsOptionsFiltered,
      currentPage,
      optionsByPage,
      onScrollOptionsEnd
    }
  },

  computed: {
    ...mapState({
      selectedCameras: state => state.reports.filtersReports.selectedCameras,
      isSelectedAll: state => state.reports.isSelectedAll,
      camerasAllHash: state => state.cameras.camerasAllHash
    }),
    isSelectAllPages() {
      return !!this.optionsByPage.length && this.optionsByPage.every(camItem => this.selectedCameras[camItem.id] !== undefined)
    },
    isCheckedAll() {
      const allCount = Object.keys(this.camerasAllHash).length
      const selectedCount = Object.keys(this.selectedCameras).length
      return selectedCount === 0 || (allCount > 0 && selectedCount === allCount)
    }
  },

  data() {
    return {
      searchModel: '',
    }
  },

  mounted() {
    if (Object.keys(this.camerasAllHash).length > 0) {
      this.getCamsOptionsAll()
    } else {
      /**
       * Если камер нет, то сделаем одну попытку их дождаться
       */
      const unwatchCameras = this.$watch('camerasAllHash', () => {
        this.getCamsOptionsAll()
        unwatchCameras()
      })
    }
    
  },

  methods: {
    ...mapMutations({
      setFilterReports: 'reports/SET_FILTER_REPORTS',
      setIsSelectAll: 'reports/SET_IS_SELETED_ALL'
    }),
    ...mapActions({
      getCamsOptionsAll: 'reports/getCamsOptionsAll',
      getCamsOptionsFiltered: 'reports/getCamsOptionsFiltered',
      checkIsSelectAll: 'reports/checkIsSelectAll',
      selectAll: 'reports/selectAll',
      uncheckAll: 'reports/uncheckAll'
    }),

    setSelectedCameras(event) {
      this.setFilterReports({ key: 'selectedCameras', value: event })
    },
    onSelectAllOption: debounce(function(event) {
      if (event) {
        this.selectAll()
      } else {
        this.uncheckAll()
      }
    }, 300),
    onQueryInput: debounce(function() {
      this.currentPage = 1
      this.getCamsOptionsFiltered(this.searchModel)
    }, 300)
  },
  watch: {
    isSelectAllPages() {
      if (this.isSelectAllPages) {
        if (!this.isSelectedAll) {
          this.checkIsSelectAll()
        }
      } else {
        this.setIsSelectAll(false)
      }
    }
  }
})
