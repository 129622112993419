
export const fieldsStatisticCommon = ['totalTime', 'notWorkingCameraCount']

export const filedsChart = [
  {
    field: 'workingTime',
    id: 10
  },
  {
    field: 'workingTimePercent',
    id: null
  },
  {
    field: 'problemTime',
    id: 30
  },
  {
    field: 'archiveTime',
    id: 70
  }
]