import { render, staticRenderFns } from "./stub.vue?vue&type=template&id=72397248&scoped=true&"
import script from "./stub.js?vue&type=script&lang=js&"
export * from "./stub.js?vue&type=script&lang=js&"
import style0 from "./stub.scss?vue&type=style&index=0&id=72397248&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72397248",
  null
  
)

export default component.exports