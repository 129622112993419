import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions } from 'vuex'
import StatisticChart from '../statistic-chart/statistic-chart.vue'
import ReportDetailCamerasModal from '@/components/modals/report-detail-cameras-modal/report-detail-cameras-modal.vue'
import { filedsChart } from '@/consts/report-charts'

export default defineComponent({
  name: 'ReportCarts',
  components: {
    StatisticChart,
    ReportDetailCamerasModal
  },

  setup() {
    return {
      filedsChart
    }
  },

  data() {
    return {
      fieldObjShowDetail: null,
      errorDetalizationField: '',
      tooltipCoords: {
        top: 0,
        left: 0
      }
    }
  },

  mounted() {
    window.addEventListener('click', this.hideTooltip)
  },

  beforeDestroy() {
    window.removeEventListener('click', this.hideTooltip)
  },

  computed: {
    ...mapState({
      reportCharts: state => state.reports.reportCharts,
      camerasReportList: state => state.reports.camerasReportList
    }),

    isSingleCamReport() {
      return this.camerasReportList.length <= 1
    }
  },

  methods: {
    ...mapActions({
      getDetailReport: 'reports/getDetailReport',
    }),

    onSetIntervalChart(fieldObj, interval) {
      if (!fieldObj.id) {
        return
      }
      this.getDetailReport({ fieldObj, chartInterval: interval })
    },

    onHistoryBack(fieldObj) {
      if (!fieldObj.id) {
        return
      }
      const history = this.reportCharts[fieldObj.field].history
      if (history.length > 1) {
        const prevItemHistory = history[history.length - 2]
        this.getDetailReport({ fieldObj, chartInterval: prevItemHistory })
      }
    },

    showCamerasInfo(fieldObj) {
      this.fieldObjShowDetail = fieldObj
      this.$nextTick(() => {
        this.$refs['report-detail-cameras-modal'].show()
      })
    },

    setTootipPosition(point) {
      const reportChartEl = this.$refs['report-charts']
      const tootipEl = this.$refs['tootip']
      const reportChartElRect = reportChartEl.getBoundingClientRect()
      const tootipElRect = tootipEl.getBoundingClientRect()
      const offset = 10
      const widthTooltip = tootipElRect.width
      const heightTootip = tootipElRect.height
      const topPos = (point.y - reportChartElRect.top) - (heightTootip +  offset)
      let leftPos = point.x - reportChartElRect.left + offset
      if (leftPos + widthTooltip > reportChartElRect.width - offset) {
        const diff = (leftPos + widthTooltip) - (reportChartElRect.width - offset)
        leftPos = leftPos - diff
      }
      this.tooltipCoords.top = topPos
      this.tooltipCoords.left = leftPos
    },

    onErrorSetInterval(point, fieldName) {
      point.stopPropagation()
      if (this.errorDetalizationField === fieldName) {
        this.hideTooltip()
        return
      }
      this.errorDetalizationField = fieldName
      this.$nextTick(() => {
        this.setTootipPosition(point)
      })      
    },

    hideTooltip() {
      this.errorDetalizationField = ''
    }
  },

})