import http from '@/services/http'
import utf8 from 'utf8'
import { i18n } from '@/i18n'

const getDefaultState = () => {
  return {
    user: null
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    /**
     * пока это повторение логики SET_USER,
     * но возможно эта функция будет расширяться
     */
    RESET_STATE(state) {
      /**
       * при удалении пользователя с auth.js,
       * эта функция вызовется два раза
       * лучше сделать хотя бы такую проверку,
       * ее тоже нужно потом будет переписать
       */
      if (state.user) {
        Object.assign(state, getDefaultState())
        localStorage.removeItem('user')
      }
    },

    /**
     * 
     * @param {*} user = { name }
     */
    SET_USER(state, user) {
      state.user = user
      if (state.user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
    }
  },
  actions: {
    login({ commit }, { login, password }) {
      return http.post('login', {
        login,
        key: btoa(utf8.encode(`${login}:${password}`))
      }).then(() => {
        commit('SET_USER', { name: login })
      })
    },
    async logout({ commit, dispatch }) {
      await http.get('logout')
      commit('SET_USER', null)
      dispatch('changeLocale', i18n.locale, { root: true })
    }
  }
}