import { defineComponent, computed } from '@vue/composition-api'
import VuejsDatepicker from 'vuejs-datepicker'
import { useDropdown } from '@/components/composition/dropdown'
import { useI18n } from 'vue-i18n-composable/dist/index.js'
import { i18n as i18nGlobal } from '@/i18n'

export default defineComponent({
  name: 'Datepicker',
  props: {
    value: {
      type: Date,
      default: null
    },
    format: {
      type: String,
      default: 'DD MMMM YYYY'
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String | Boolean,
      default: ''
    },
    disabledDates: {
      type: Object
    }
  },
  components: {
    VuejsDatepicker
  },
  setup(props, context) {
    const i18n = useI18n()

    const { dropdown, open, toggleOpen } = useDropdown()
    const customFormatter = date => {
      if (!date) return ''
      return  context.root.$moment(date).format(props.format)
    }
    const onInput = event => {
      context.emit('input', event)
    }

    let locales = {}

    Object.keys(i18nGlobal.messages).forEach(key => {
      locales[key] = i18nGlobal.messages[key].dateTrainslate
    })

    const currentLocale = computed(() => {
      /**
       * к тому времени как это вычислится уже будут загружены все locales
       */
      return i18n.locale.value
    })

    return {
      dropdown,
      open,
      toggleOpen,
      customFormatter,
      onInput,
      locales,
      currentLocale
    }
  }
})
