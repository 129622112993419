var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-actions"},[_c('div',{staticClass:"table-actions_left"},[_c('div',{staticClass:"table-actions__total-info"},[_vm._v(" "+_vm._s(_vm.$t('findedTotalCameras', { total: _vm.camerasTableList.length }))+" ")]),_c('button',{staticClass:"table-actions__select-all form-btns__btn",on:{"click":function($event){return _vm.onSelectAll(!_vm.isSelectAllTable)}}},[(!_vm.isSelectAllTable)?_c('span',{staticClass:"form-btns__btn--text form-btns__btn--select-all"},[_vm._v(" "+_vm._s(_vm.$t('buttons.selectAll'))+" ")]):_c('span',{staticClass:"form-btns__btn--text form-btns__btn--select-all"},[_vm._v(" "+_vm._s(_vm.$t('buttons.resetAll'))+" ")])]),_c('button',{staticClass:"btn table-actions_left__btn",class:{
        loading: _vm.updateLoading
      },on:{"click":_vm.updateCameras}},[(_vm.updateLoading)?_c('loader-btn'):_c('base-icon',{staticClass:"table-actions_left__btn__icon",attrs:{"name":"ic-refresh"}}),_c('div',{staticClass:"table-actions_left__btn__text"},[_vm._v(" "+_vm._s(_vm.$t('buttons.refreshList'))+" ")])],1),_c('router-link',{attrs:{"custom":"","to":{
        name: 'reports'
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
return [_c('button',{staticClass:"btn table-actions_left__btn",class:{
          disabled: _vm.disableCreateReport
        },on:{"click":function($event){_vm.changeReportsFilters(); navigate($event)}}},[_c('base-icon',{staticClass:"table-actions_left__btn__icon",attrs:{"name":"ic-report"}}),_c('div',{staticClass:"table-actions_left__btn__text"},[_vm._v(" "+_vm._s(((_vm.$t('buttons.createReport')) + " (" + _vm.selectedTableCameraCount + ")"))+" ")])],1)]}}])})],1),_c('div',{staticClass:"table-actions_right"},[_c('edit-cameras',{attrs:{"disabled-edit-cameras":_vm.disableCreateReport}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }