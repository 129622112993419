import { render, staticRenderFns } from "./passport-form.vue?vue&type=template&id=decd9c86&scoped=true&"
import script from "./passport-form.js?vue&type=script&lang=js&"
export * from "./passport-form.js?vue&type=script&lang=js&"
import style0 from "./passport-form.scss?vue&type=style&index=0&id=decd9c86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "decd9c86",
  null
  
)

export default component.exports