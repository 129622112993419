import { VueGoodTable } from 'vue-good-table'
import {
  DEFAULT_SORT_TYPE,
  SORT_TYPES,
} from 'vue-good-table/src/components/utils/constants'

function getColumnFirstSortType(column) {
  return column.firstSortType || DEFAULT_SORT_TYPE
}

function getCurrentPrimarySort(sortArray, column) {
  return ( sortArray.length === 1 && sortArray[0].field === column.field )
  ? sortArray[0].type
  : undefined
}

function getNextSort(currentSort) {
  if (currentSort === SORT_TYPES.Ascending) {
    return SORT_TYPES.Descending
  }
  if (currentSort === SORT_TYPES.Descending) {
    return SORT_TYPES.Ascending
  }
}

const primarySort = (sortArray, column) => {
  const currentPrimarySort = getCurrentPrimarySort(sortArray, column)
  const nextPrimarySort = getNextSort(currentPrimarySort, column)
  return [{
    field: column.field,
    type: currentPrimarySort ? nextPrimarySort : getColumnFirstSortType(column)
  }]
}

const VgtTableHeader = VueGoodTable.components['vgt-table-header']

export default {
  extends: VueGoodTable,
  components: {
    'vgt-table-header': {
      extends: VgtTableHeader,
      methods: {
        sort(e, column) {
          this.sorts = primarySort(this.sorts, column)
          this.$emit('on-sort-change', this.sorts)
        }
      }
    }
  }
}