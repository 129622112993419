export const restrictMaxValue = (value, limit) => {
  if (!value) {
    return ''
  }
  let correctedValue = value.replace(/\D/g, '')
  if (correctedValue.search(/0+/) === 0) {
    correctedValue = correctedValue.replace(/0+/, '')
  }
  const testNum = +correctedValue
  if (testNum > limit) {
    correctedValue = restrictMaxValue(correctedValue.slice(0, correctedValue.length - 1), limit)
  }

  return correctedValue
}