
/**
 * хук на смену статуса и одновременного его применения
 */
export const useSetStatusCameras = (store) => {
  /**
   * 
   * @param {{ id, name }} statusObject 
   */
  const setStatus = async (statusObject) => {
    const loading = store.state.cameras.loading
    if (!loading) {
      store.commit('cameras/SET_DEFAULT_FILTERS')
      store.commit('cameras/SET_FILTER', {
        key: 'statusCameras',
        value: {
          [statusObject.id]: statusObject
        }
      })
      store.dispatch('cameras/applyFilters')
    }
  }
  return {
    setStatus
  }
}