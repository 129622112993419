
/**
 * В общие утилиты стора (@/store/utils) нельзя положить из-за воркеров
 */
/**
 * 
 * @param {*} camItemFull 
 * @returns должны входить все поля для поиска checkQ в файле src/filter-cams-utils.js
 */
export const getSelectedTablCam = camItemFull => ({
  id: camItemFull.id,
  name: camItemFull.name,
  status: camItemFull.status,
  status_exploitation: camItemFull.status_exploitation,
  ip_server: camItemFull.ip_server
})

export const addSelectedCameraTable = (selectedCameraList, camItem, addFn = (camerasHash, camItem) => {
  camerasHash[camItem.id] = getSelectedTablCam(camItem)
}) => {
  if (!selectedCameraList.cameras[camItem.id]) {
    addFn(selectedCameraList.cameras, camItem)
    camItem.status_exploitation !== 'notFilled' ? 
      selectedCameraList.isPassport += 1 : 
      selectedCameraList.isNotPassport += 1
    
    if (camItem.status_exploitation === 'introduced') {
      selectedCameraList.countPassportActivate += 1
    }

    if (camItem.status_exploitation === 'notVerified') {
      selectedCameraList.notActivated += 1
    }
  }
}

export const removeSelectedCameraTable = (selectedCameraList, camItem, deleteFn = (camerasHash, camItemId) => {
  delete camerasHash[camItemId]
} ) => {
  if (selectedCameraList.cameras[camItem.id]) {
    deleteFn(selectedCameraList.cameras, camItem.id)
    camItem.status_exploitation !== 'notFilled' ? 
      selectedCameraList.isPassport -= 1 : 
      selectedCameraList.isNotPassport -= 1
    
    if (camItem.status_exploitation === 'introduced') {
      selectedCameraList.countPassportActivate -= 1
    }

    if (camItem.status_exploitation === 'notVerified') {
      selectedCameraList.notActivated -= 1
    }
  }
}

export const changeSelectedCameraExp = (selectedCameraList, camItem, oldStatusExp) => {
  if (selectedCameraList.cameras[camItem.id]) {
    const newStatusExp = camItem.status_exploitation
    if (oldStatusExp === 'notFilled' && newStatusExp !== 'notFilled') {
      selectedCameraList.isNotPassport -= 1
      selectedCameraList.isPassport += 1
    }
    if (oldStatusExp !== 'notFilled' && newStatusExp === 'notFilled') {
      selectedCameraList.isNotPassport += 1
      selectedCameraList.isPassport -= 1
    }
    if (oldStatusExp !== 'introduced' && newStatusExp === 'introduced') {
      selectedCameraList.countPassportActivate += 1
    }
    if (oldStatusExp === 'introduced' && newStatusExp !== 'introduced') {
      selectedCameraList.countPassportActivate -= 1
    }

    if (oldStatusExp !== 'notVerified' && camItem.status_exploitation === 'notVerified') {
      selectedCameraList.notActivated += 1
    }

    if (oldStatusExp === 'notVerified' && camItem.status_exploitation !== 'notVerified') {
      selectedCameraList.notActivated -= 1
    }
    /**
     * обязательно в конце перезаписываем
     */
    selectedCameraList.cameras[camItem.id] = camItem
  }
}

export const checkIsSelectAll = (camsList, selectedCameras) => {
  let isSelectAll = true
  /**
   * чтобы не всегда перебирать весь массив через every
   */
  for (let i = 0; i < camsList.length; i++) {
    const item = camsList[i]
    if (selectedCameras[item.id] === undefined) {
      isSelectAll = false
      break
    }
  }
  return isSelectAll
}