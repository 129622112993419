import { defineComponent, computed, ref } from '@vue/composition-api'
import { mapState, mapActions } from 'vuex'
import { useClientSelectPagination } from '@/components/composition/client-page-pagination'

export default defineComponent({
  name: 'IpServerSelect',

  props: {
    /**
     * { id: {id: string, name: string} }
     */
    value: {
      type: Object,
      default: () => ({})
    }
  },

  setup() {
    const options = ref([])

    const query = ref('')

    const optionsFiltered = computed(() => {
      return options.value.filter(item => {
        return item.name.toLowerCase().indexOf(query.value.toLowerCase()) !== -1
      })
    })

    const {
      currentPage,
      optionsByPage,
      onScrollOptionsEnd
    } = useClientSelectPagination(optionsFiltered)
    return {
      options,
      query,
      optionsFiltered,
      currentPage,
      optionsByPage,
      onScrollOptionsEnd
    }
  },

  data() {
    return {
      currentPage: 1
    }
  },

  computed: {
    ...mapState({
      updateLoading: state => state.update.loading,
      wasError: state => state.update.wasError,
    }),
    isSelectedAll() {
      return !!this.optionsFiltered.length && this.optionsFiltered.every(option => this.value[option.id] !== undefined)
    },
    isCheckedAll() {
      const allCount = this.options.length
      const selectedCount = Object.keys(this.value).length
      return selectedCount === 0 || (allCount > 0 && selectedCount === allCount)
    }
  },

  methods: {
    ...mapActions({
      getIpServer: 'cameras/getIpServer',
    }),
    setOptions() {
      this.getIpServer().then(options => {
        this.options = options
      })
    },

    onQueryInput() {
      this.currentPage = 1
    },

    onInput(event) {
      this.$emit('input', event)
    },

    onSelectAllOption(event) {
      const newValue = { ...this.value }
      if (event) {
        this.optionsFiltered.forEach(option => {
          newValue[option.id] = option
        })
      } else {
        this.optionsFiltered.forEach(option => {
          delete newValue[option.id]
        })
      }

      this.$emit('input', newValue)
    }
  },

  watch: {
    updateLoading: {
      immediate: true,
      handler() {
        if (!this.updateLoading && !this.wasError) {
          this.setOptions()
        }
      }
    }

  }

})