import { i18n } from '@/i18n'

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `../../../public/locales/${lang}.js`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}

export async function useLoadLanguageAsync(store, lang, isStart = false) {

  /**
   * Отправляем язык на сервер (не ждем окончания запроса, здесь это не принципиально)
   */

  store.dispatch('changeLocale', lang)

  if (isStart) {
    store.commit('SET_LOCALE_LOADING', true)
  }

  /***
   * Получаем свои переводы
   */

  await loadLanguageAsync(lang)

  /**
   * завершаем лоадинг
   */

  if (isStart) {
    store.commit('SET_LOCALE_LOADING', false)
  }
}