import { defineComponent } from '@vue/composition-api'
import { mapState, mapMutations, mapActions } from 'vuex'
import { useDropdown } from '@/components/composition/dropdown'
import ConfirmAlertModal from '@/components/modals/confirm-alert-modal/confirm-alert-modal.vue'

export default defineComponent({
  name: 'ExportDropdown',

  components: {
    ConfirmAlertModal
  },

  setup() {
    const exportOptions = [
      {
        id: 'xlsx',
        name: 'Excel'
      },
      {
        id: 'html',
        name: 'html'
      }
    ]

    const { dropdown, open, toggleOpen } = useDropdown()

    return {
      exportOptions,
      dropdown,
      open,
      toggleOpen,
      savedExportType: '',
      limitSeparateReport: 1000
    }
  },

  data() {
    return {
      isSeparated: false,
    }
  },

  computed: {
    ...mapState({
      exportLoading: state => state.reports.exportLoading,
      dataReport: state => state.reports.dataReport,
    }),

    isPluralCameras() {
      return this.dataReport && this.dataReport.countCameras > 1
    },
  },

  methods: {
    ...mapActions({
      getFileReport: 'reports/getFileReport',
      stopExportRequest: 'reports/stopExportReport'
    }),

    ...mapMutations({
      setExportLoading: 'reports/SET_EXPORT_LOADING'
    }),

    exportReport() {
      this.getFileReport({ type: this.savedExportType, isSeparate: this.isSeparated })
    },

    downloadReport(exportOption) {
      this.savedExportType = exportOption.id
      if (this.dataReport.countCameras > this.limitSeparateReport && this.isSeparated) {
        this.$refs['separate-report-modal'].show()
      } else {
        this.exportReport()
      }
    },

    stopExportReport() {
      this.stopExportRequest()
      this.setExportLoading(false)
    },

    cancelSeparateReport() {
      this.savedExportType = ''
    },
    
  }
})
