import { defineComponent, ref } from '@vue/composition-api'
import { inputLength } from '@/consts/input-length'

export default defineComponent({
  name: 'VInput',
  props: {
    placeholder: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    mask: {
      type: String | Object
    },
    value: {
      type: String | Number
    },
    icon: {
      type: String
    },
    /**
     * Нужно например для селекта, делает класс focus на input
     */
    focus: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    },
    iconClickable: {
      type: Boolean,
      default: false
    },
    selectToggle: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean | String | Number,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  setup(props, context) {
    const input = ref(null)
    /**
     * флаг, находится ли мышь на кнопке очистки
     */
    const isOnClearBtn = ref(false)

    const onInput = e => {
      const inputValue = e.target.value
      if (props.mask) {
        context.emit('input', inputValue)
        return
      }
      if (inputValue.length > inputLength) {
        e.target.value = inputValue.slice(0, inputLength)
        return
      }
      context.emit('input', inputValue)
    }
    const onChange = () => {
      if (!isOnClearBtn.value) {
        context.emit('change')
      }
    }
    const onClear = () => {
      context.emit('input', '')
      /**
       * для валидатора
       */
      context.emit('change')
      context.emit('clear')
      isOnClearBtn.value = false
      input.value.focus()
    }
    const onClickIcon = () => {
      if (props.iconClickable) {
        context.emit('click-icon')
      } else if (props.selectToggle) {
        context.emit('click')
      }
    }
    return {
      input,
      isOnClearBtn,
      onInput,
      onChange,
      onClear,
      onClickIcon
    }
  }
})