import Vue from 'vue'
import Router from 'vue-router'
import AuthPage from '@/pages/auth-page/auth-page.vue'
import MainPage from '@/pages/main-page/main-page.vue'
import ReportsPage from '@/pages/reports-page/reports-page.vue'
import store from '@/store'

Vue.use(Router)


const routes = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthPage
  },
  {
    path: '/main',
    name: 'main',
    component: MainPage
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsPage
  },
  { path: '*', redirect: '/' }
]

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('SET_ERROR_CONNECTION', false)
  if(to.name !== 'auth') {
    if (store.state.users.user || localStorage.getItem('user')) {
      return next()
    }
    return next({
      name: 'auth'
    })
  }
  next()
})

export default router