import { render, staticRenderFns } from "./table-data-chart.vue?vue&type=template&id=f1a8aa78&scoped=true&"
import script from "./table-data-chart.js?vue&type=script&lang=js&"
export * from "./table-data-chart.js?vue&type=script&lang=js&"
import style0 from "./table-data-chart.scss?vue&type=style&index=0&id=f1a8aa78&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a8aa78",
  null
  
)

export default component.exports