import { useGetDateLabel } from './useGetDateLabel'

export const useResizeColumnsChart = () => {
  const { getDateLabel } = useGetDateLabel()
  const resizeColumnsChart = (chart, timeUnit) => {
    const xAxis = chart.scales['x-axis-0']
    const scaleWidth = xAxis.width
    const lableItems = xAxis._labelItems
    const keysMeta = Object.keys(chart.config.data.datasets[0]._meta)
    const datasetChartEls = chart.config.data.datasets[0]._meta[keysMeta[0]].data
    const columnWidth = scaleWidth / datasetChartEls.length
    for (let i = 0; i < datasetChartEls.length; i++) {
      const model = datasetChartEls[i]._model
      model.width = columnWidth
    }
    for (let i = 0; i < lableItems.length; i++) {
      const lableItem = lableItems[i]
      lableItem.x = lableItem.x - columnWidth / 2
    }
    if (lableItems.length) {
      /**
       * возмем последний тик
       */
      const addedItem = {...lableItems[lableItems.length - 1]}
      const dataset = chart.config.data.datasets[0].data
      addedItem.label = getDateLabel(dataset[dataset.length - 1].end, timeUnit, true)
      addedItem.x = addedItem.x + columnWidth
      lableItems.push(addedItem)
    }
  }
  
  return {
    resizeColumnsChart
  }
}