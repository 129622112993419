import { defineComponent } from '@vue/composition-api'
import { restrictMaxValue } from '../composition/restrictMaxValue'

export default defineComponent({
  name: 'RestrictedInput',
  props: {
    value: {
      type: String | Number,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
      restrictMaxValue
    }
  },
  methods: {
    onInput(event) {
      const value = event.target.value
      const cursorPos = event.target.selectionStart
      event.target.value = this.restrictMaxValue(value, this.limit)
      this.$emit('input', event.target.value)
      event.target.setSelectionRange(cursorPos, cursorPos)
    }
  }
})