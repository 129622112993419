import { defineComponent } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DateTime from '@/components/date-time/date-time.vue'
import { blurForm } from '@/components/composition/blur-elements'

/**
 * Пришлось записать в опциональном стиле, 
 * так как ref observer не опрделялся
 * Все что внутри modal нужно писать в опциональном стиле
 */
export default defineComponent({
  name: 'TimeIntervalForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    DateTime
  },
  data() {
    return {
      form: {
        startDate: null,
        endDate: null
      },
      notMatchError: false,
      dateError: '',
      /**
       * для ошибок с длинным текстом
       */
      formStyles: null
    }
  },
  created() {
    this.setDefaultValues()
  },
  methods: {
    setDefaultValues() {
      this.form.startDate = this.$moment()
      .startOf('minute')
      .subtract(1, 'hour'),
      this.form.endDate = this.$moment().startOf('minute')
    },
    submit(e) {
      blurForm(e.target)
      this.$refs.observer.validate().then(success => {
        if (!success || this.notMatchError) {
          return
        }
        this.$emit('submit', this.form)
        this.hide()
      })
    },
    onInput(formField) {
      this.form[formField] = this.form[formField].startOf('minute')
      this.notMatchError = false
      if (this.form.endDate.diff(this.form.startDate, 'hours') < 1) {
        this.dateError = 'dates_not_match'
        this.notMatchError = true
      }
      else if (this.form.endDate.diff(this.form.startDate, 'years') >= 5) {
        this.dateError = 'invalid_time_interval'
        this.notMatchError = true
      }
      
    },
    show () {
      this.$refs.modal.show()
      this.$nextTick(() => {
        const width = this.$refs.observer.$el.getBoundingClientRect().width
        this.formStyles = {
          width: width + 'px'
        }
      })
    },
    hide () {
      this.$refs.modal.hide()
    },
    onClose() {
      this.formStyles = null
      this.notMatchError = false
      this.setDefaultValues()
      this.$refs.observer.reset()
    }
  },
  watch: {
    '$i18n.locale'() {
      if (this.form.startDate) {
        this.form.startDate.locale(this.$i18n.locale)
      }
      if (this.form.endDate) {
        this.form.endDate.locale(this.$i18n.locale)
      }
    }
  }
})