import { defineComponent } from '@vue/composition-api'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { SELECT_ALL } from '@/consts/select-all-option'
import ConfirmAlertModal from '@/components/modals/confirm-alert-modal/confirm-alert-modal.vue'
import GroupPassportModal from '@/components/modals/group-passport-modal/group-passport-modal.vue'
import LoaderModal from '@/components/modals/loader-modal/loader-modal.vue'
import EditCamerasResult from '@/components/modals/edit-cameras-result-modal/edit-cameras-result-modal.vue'


export default defineComponent({
  name: 'EditCameras',
  components: {
    ConfirmAlertModal,
    GroupPassportModal,
    LoaderModal,
    EditCamerasResult
  },
  props: {
    disabledEditCameras: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      editModalsTxts: {
        titles: ['fillPasportsModal.title', 'checkIntroducedCamerasModal.title'],
        discriptions: ['fillPasportsModal.description', 'checkIntroducedCamerasModal.description']
      },
      monitoringModalsTxts: {
        titles: ['emptyPassportModal.title', 'ignoreIntroducedCamerasModal.title'],
        discriptions: ['emptyPassportModal.description', 'ignoreIntroducedCamerasModal.description']
      },
      loaderTexts: {
        discriptions: ['loaderModal.editPassports', 'loaderModal.updateStatistic']
      }
    }
  },
  data() {
    return {
      selectedAll: SELECT_ALL,
      //какую по счету показывать модалку
      modalFirst: true,
      //какая кнопка нажата
      editPassports: true,
      isModalShow: false,
      /**
       * результат разный в зависимости от кнопки
       */
      updatedRes: null,
      startPassport: null
    }
  },
  computed: {
    ...mapState({
      selectedCameraList: state => state.selectedCamerasTable.selectedCameraList,
      filtersReportMain: state => state.reports.filtersMain,
      updateLoading: state => state.update.loading,
      camerasPagination: state => state.cameras.pagination,
      camerasList: state => state.cameras.list
    }),

    ...mapGetters({
      selectedTableCameraCount: 'selectedCamerasTable/selectedTableCameraCount'
    }),

    modalTexts() {
      return this.editPassports ? this.editModalsTxts : this.monitoringModalsTxts
    },

    indexModalTxts() {
      return this.modalFirst ? 0 : 1
    },

    resultModalType() {
      if (this.editPassports) {
        return 'passports'
      }
      return 'exploitation'
    },

    hasFilledPassports() {
      return this.selectedCameraList.isPassport
    },

    hasIsNotPassport() {
      return this.selectedCameraList.isNotPassport
    },
    
    hasIntroducedCameras() {
      return this.selectedCameraList.countPassportActivate !== 0
    },

    singleCamera() {
      let singleCamera = null
      const { selectedCameras, isSelectAll } = this.filtersReportMain
      if (Array.isArray(selectedCameras) && selectedCameras.length === 1 && !isSelectAll) {
        singleCamera = selectedCameras[0]
      } else if (isSelectAll && this.camerasPagination.total === 1) {
        singleCamera = this.camerasList[0]
      }
      return singleCamera
    }
  },
  methods: {
    ...mapActions({
      updateCameras: 'update/updateCameras',
      createUpdatePassportsGroup: 'cameras/createUpdatePassportsGroup',
      groupCamerasStatistic: 'cameras/groupCamerasStatistic',
      /**
       * получение пасспорта
       */
      updateCameraInfo: 'cameras/updateCameraInfo'
    }),
    ...mapMutations({
      setGlobalLoading: 'SET_GLOBAL_LOADING'
    }),

    async openModal(editPassports = false) {
      /**
       * обнуляем, чтобы избегать ошибок в edit-cameras-result-modal
       */
      this.updatedRes = null
      this.startPassport = null
      if (editPassports) {
        if (this.selectedCameraList.cameras.length === 1) {
          const cameraInfo = await this.updateCameraInfo(this.selectedCameraList.cameras[0].id)
          if (cameraInfo.passport) {
            this.startPassport = cameraInfo.passport
          }
        }
      }
      this.modalFirst = true
      this.editPassports = editPassports
      if ((editPassports && this.hasFilledPassports) || !editPassports && this.hasIsNotPassport) {
        this.$refs['modal-edit-cameras'].show()
      } else if (this.hasIntroducedCameras) {
        this.modalFirst = false
        this.$refs['modal-edit-cameras'].show()
      } else {
        this.invokeThirdStep()
      }
    },
    onApplyModal() {
      if (this.modalFirst) {
        if (this.hasIntroducedCameras) {
          /**
           * при this.hasFilledPassports может быть еще true
           */
          this.modalFirst = false
        } else {
          this.invokeThirdStep()
        }
      } else {
        this.invokeThirdStep()
      }
    },

    invokeThirdStep() {
      if (this.editPassports) {
        this.showGroupPassportsModal()
      } else {
        this.onUpdateStatistic()
      }
    },

    showGroupPassportsModal() {
      this.$refs['modal-edit-cameras'].hide()
      /**
       * сеттаймаут для перерисовки модалки
       */
      setTimeout(() => {
        this.$refs['group-passport-modal'].show()
      });
    },

    async submitWithLoader(req) {
      setTimeout(() => {
        this.$refs['modal-edit-cameras-loader'].show()
      })
      try {
        const res = await req
        this.updatedRes = res
        await this.updateCameras()
        /**
         * не отрисовывает стили без сеттаймаута
         */
        setTimeout(() => {
          this.$refs['modal-edit-cameras-result'].show()
        })
      } finally {
        this.$refs['modal-edit-cameras-loader'].hide()
      }
    },

    /**
     * обновление паспортов на кнопку сохранить в форме
     */
    async onSubmitPassportGroup(event) {
      this.$refs['group-passport-modal'].hide()
      const sendObj = { ...event }
      if (!sendObj.fps) {
        sendObj.fps = 0
      }
      if (!sendObj.kbps) {
        sendObj.kbps = 0
      }
      const req = this.createUpdatePassportsGroup({
        data: {
          ...sendObj
        }
      })
      await this.submitWithLoader(req)
    },

    async onUpdateStatistic() {
      this.$refs['modal-edit-cameras'].hide()
      const req = this.groupCamerasStatistic()
      await this.submitWithLoader(req)
    }
  }
})
