var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-data-chart"},[_c('div',{staticClass:"table-data-chart__chart",class:{
      disabled: _vm.loading,
      hovered: _vm.chartHover
    }},[_c('doughnut-chart',{staticClass:"table-data-chart__diagram",attrs:{"chartData":{
        labels: _vm.cameraStatuses.map(function (s) { return _vm.$t(("status.camera." + s)); }),
        datasets:[
          {
            data: _vm.chartData,
            backgroundColor:[
              '#1E6B5D',
              '#C94427',
              '#59347E',
              '#515D7A'
            ],
            borderWidth: 0
          }
        ]
      }},on:{"mouseenter":_vm.onMouseChartEnter,"mouseout":_vm.onMouseChartOut,"click":_vm.onChartClick}})],1),_c('button',{staticClass:"btn table-data-chart__total",attrs:{"type":"button"},on:{"click":_vm.clearAllFilters}},[_vm._v(" "+_vm._s(_vm.$t('statistic.totalCameras', { total: _vm.totalCameras }))+" ")]),_c('div',{staticClass:"table-data-chart__legend"},_vm._l((_vm.chartLegend),function(legend,index){return _c('div',{key:index,class:[("status-camera status-camera--" + (legend.status)), {disabled: _vm.loading}],on:{"click":function($event){return _vm.setStatus(legend.statusFilterItem)}}},[_vm._v(" "+_vm._s(("(" + (legend.total) + ") " + (_vm.$t(("status.camera." + (legend.status))))))+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }