import { defineComponent } from '@vue/composition-api'
import { Bar, mixins,  } from 'vue-chartjs'
import { useResizeColumnsChart } from './composition/useResizeColumnsChart'

export default defineComponent({
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['options', 'timeUnit'],
  setup() {
    const { resizeColumnsChart } = useResizeColumnsChart()
    return {
      resizeColumnsChart,
      isNeedRerender: true
    }
  },
  mounted () {
    this.addPlugin({
      id: 'afterDrawPlugin',
      afterDraw: this.onAfterDraw,
      resize: this.onResize,
    })
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    onAfterDraw(chart) {
      if (this.isNeedRerender) {
        this.isNeedRerender = false
        this.resizeColumnsChart(chart, this.timeUnit)
        chart.render()
        const chartAreaHeight = chart.chartArea.bottom
        this.$emit('set-chart-area-height', chartAreaHeight)
      }
    },
    onResize() {
      this.isNeedRerender = true
    }
  },
  watch: {
    chartData() {
      this.isNeedRerender = true
    },
    '$i18n.locale'() {
      this.renderChart(this.chartData, this.options)
    }
  }
})