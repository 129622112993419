var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",staticClass:"passport-form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{staticClass:"passport-form__form-group",attrs:{"tag":"label","rules":{
      required: _vm.isFiledRequired
    },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"passport-form__label"},[_vm._v(" "+_vm._s(_vm.$t('cameraPrams.passport.fps'))+" ")]),_c('restricted-number-input',{staticClass:"passport-form__input",class:{
        error: errors.length
      },attrs:{"placeholder":_vm.$t('cameraPrams.passport.fps'),"limit":999},model:{value:(_vm.form.fps),callback:function ($$v) {_vm.$set(_vm.form, "fps", $$v)},expression:"form.fps"}})]}}])}),_c('validation-provider',{staticClass:"passport-form__form-group",attrs:{"tag":"label","rules":{
      required: _vm.isFiledRequired,
      resolution: _vm.isFiledResolutionRequired
    },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"passport-form__label"},[_vm._v(" "+_vm._s(_vm.$t('cameraPrams.passport.resolution'))+" ")]),_c('resolution-input',{staticClass:"passport-form__input",class:{
        error: errors.length
      },attrs:{"placeholder":_vm.$t('cameraPrams.passport.resolution'),"limit":65535},on:{"input":_vm.onInputResolution},model:{value:(_vm.resolutionStrEdit),callback:function ($$v) {_vm.resolutionStrEdit=$$v},expression:"resolutionStrEdit"}})]}}])}),_c('validation-provider',{staticClass:"passport-form__form-group",attrs:{"tag":"label","rules":{
      required: _vm.isFiledRequired
    },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"passport-form__label"},[_vm._v(" "+_vm._s(_vm.$t('cameraPrams.passport.kbps'))+" ")]),_c('restricted-number-input',{staticClass:"passport-form__input",class:{
        error: errors.length
      },attrs:{"placeholder":_vm.$t('cameraPrams.passport.kbps'),"limit":100000},model:{value:(_vm.form.kbps),callback:function ($$v) {_vm.$set(_vm.form, "kbps", $$v)},expression:"form.kbps"}})]}}])}),_c('div',{staticClass:"form-btns"},[_c('button',{staticClass:"btn form-btns__btn btn-save",class:{
        disabled: _vm.isFiledRequired || _vm.isFiledResolutionRequired || _vm.loading
      },attrs:{"type":"submit"}},[_c('base-icon',{staticClass:"form-btns__btn--icon",attrs:{"name":"ic-save"}}),_c('span',{staticClass:"form-btns__btn--text"},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1),_c('button',{staticClass:"btn form-btns__btn btn-cancel",class:{
        disabled: _vm.loading
      },attrs:{"type":"button"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }