import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FixFluidColGrid',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
