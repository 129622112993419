import { defineComponent } from '@vue/composition-api'
import CameraNamesList from '@/components/camera-names-list/camera-names-list.vue'

export default defineComponent({
  name: 'CameraNamesListWrapper',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  components: {
    CameraNamesList
  },

  data() {
    return {
      fullList: false
    }
  }
})