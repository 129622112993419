import { defineComponent } from '@vue/composition-api'
import CamerasTableModule from '@/components/cameras-table-module/cameras-table-module.vue'


export default defineComponent({
  name: 'Main',
  components: {
    CamerasTableModule
  },

})