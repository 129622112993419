import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
import Stub from '@/components/stub/stub.vue'
import ReportsFilterForm from '@/components/reports-module/components/reports-filter-form/reports-filter-form.vue'
import CommonReportInfo from '@/components/reports-module/components/common-report-info/common-report-info.vue'
import ReportCharts from '@/components/reports-module/components/report-charts/report-charts.vue'

export default defineComponent({
  name: 'ReportsModule',
  components: {
    Stub,
    ReportsFilterForm,
    CommonReportInfo,
    ReportCharts
  },

  computed: {
    ...mapState({
      loading: state => state.reports.loading,
      dataReport: state => state.reports.dataReport,
    }),
  },
})