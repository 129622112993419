import Vue from 'vue'
import BaseIcon from '@/components/base-icon/base-icon.vue'
import VCheckbox from '@/components/ui/v-checkbox/v-checkbox.vue'
import VInput from '@/components/ui/v-input/v-input.vue'
import VSelect from '@/components/ui/v-select/v-select.vue'
import VToggle from '@/components/ui/v-toggle/v-toggle.vue'
import Modal from '@/components//modals/modal/modal.vue'
import FixFluidColGrid from '@/components/fix-fluid-col-grid/fix-fluid-col-grid.vue'
import Loader from '@/components/loader/loader.vue'
import LoaderBtn from '@/components/loader-btn/loader-btn.vue'

Vue.component('base-icon', BaseIcon)
Vue.component('v-checkbox', VCheckbox)
Vue.component('v-input', VInput)
Vue.component('v-select', VSelect)
Vue.component('v-toggle', VToggle)
Vue.component('modal', Modal)
Vue.component('fix-fluid-col-grid', FixFluidColGrid)
Vue.component('loader', Loader)
Vue.component('loader-btn', LoaderBtn)
