import { mapActions, mapMutations, mapState } from 'vuex'
import { defineComponent, onMounted } from '@vue/composition-api'
import Header from '@/components/header/header.vue'
import ConfirmAlertModal from '@/components/modals/confirm-alert-modal/confirm-alert-modal.vue'
import Stub from '@/components/stub/stub.vue'
import { startLocale } from '@/i18n'
import { useLoadLanguageAsync } from '@/components/composition/loading-translations'

export default defineComponent({
  name: 'App',
  components: {
    VHeader: Header,
    ConfirmAlertModal,
    Stub
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      globalLoading: state => state.globalLoading,
      localeLoading: state => state.localeLoading,
      errorConnection: state => state.errorConnection,
      serverError: state => state.serverError,
    })
  },

  setup(props, context) {
    onMounted(() => {
      useLoadLanguageAsync(context.root.$store, startLocale, true)
    })
  },

  created() {
    const userStorage = localStorage.getItem('user')
    
    if (userStorage) {
      this.setUser(JSON.parse(userStorage))
    }
  },

  methods: {
    ...mapActions({
      cancelCheckJob: 'update/cancelCheckJob',
      updateCameras: 'update/updateCameras',
      resetGlobalState: 'resetGlobalState'
    }),
    ...mapMutations({
      setServerError: 'SET_SERVER_ERROR',
      setUser: 'users/SET_USER',
      setGlobalLoading: 'SET_GLOBAL_LOADING'
    })
  },
  watch: {
    serverError: {
      /**
       * После загрузки языка ошибка уже может быть
       */
      immediate: true,
      handler()      {
        const isShowDialog = this.$route.name !== 'auth' && 
          this.serverError &&
          this.serverError.data &&
          this.serverError.data.isDialog
        
        if (this.serverError && this.serverError.status === 500 || isShowDialog) {
          //на случай, если после группового обновления паспортов вылетит ошибка. Снова не отрисовывает стили без settimeout
          setTimeout(() => {
            this.$refs['server-error-modal'].show()
          })
        }
      },
    },
    /**
     * изначально пользователя нет,
     * поэтому сюда он зайдет
     */
     user() {
      if (this.user) {
        this.setGlobalLoading(true)
        this.updateCameras()
        .finally(this.setGlobalLoading(false))
      } else {
        /**
         * скорее всего это вызвано переходом
         * на страницу auth c очисткой текущего юзера,
         * поэтому ошибки нужно сохранить, для отображения
         * в форме авторизации
         */
        this.cancelCheckJob().then(() => {
          this.resetGlobalState([ 'serverError' ])
        })
      }
    }
  }
})