import Vue from 'vue'
import camerasWorker from '@/worker'
import {  camerasSelectSeveralTableStart,
          camerasUncheckSeveralTableStart,
          camerasGetIsSelectAllStart
       } from '@/worker/worker-actions'
import {  addSelectedCameraTable,
          removeSelectedCameraTable,
          changeSelectedCameraExp,
          getSelectedTablCam,
        } from './add-remove-selected-utils'

export class SelectedCameraList {
  constructor() {
    /**
     * id: { id, name, status, status_exploitation }
     */
    this.cameras = {}
    this.isPassport = 0
    this.isNotPassport = 0
    this.countPassportActivate = 0
    this.notActivated = 0
  }
}

const getDefaultState = () => {
  return {
    selectedCameraList: new SelectedCameraList(),
    isSelectAllTable: false
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    selectedTableCameraCount(state) {
      return Object.values(state.selectedCameraList.cameras).length
    }
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },

    SET_SELECTED_CAMERA_LIST(state, selectedCameraList) {
      state.selectedCameraList = selectedCameraList
    },

    RESET_SELECTED_CAMERA_LIST(state) {
      state.selectedCameraList = new SelectedCameraList()
    },

    ADD_SELECTED_CAMERA(state, camItem) {
      addSelectedCameraTable(state.selectedCameraList, camItem, (camerasHash, camItem) => {
        Vue.set(camerasHash, camItem.id, getSelectedTablCam(camItem))
      })
    },

    REMOVE_SELECTED_CAMERA(state, camItem) {
      removeSelectedCameraTable(state.selectedCameraList, camItem, (camerasHash, camItemId) => {
        Vue.delete(camerasHash, camItemId)
      })
    },

    CHANGE_SELECTED_CAMERA_STATUS_EXP(state, { camItem,  oldStatusExp }) {
      changeSelectedCameraExp(state.selectedCameraList, camItem,  oldStatusExp)
    },

    SET_IS_SELECT_ALL_TABLE(state, isSelectAllTable) {
      state.isSelectAllTable = isSelectAllTable
    }
  },
  actions: {
    async selectSeveralCamerasTable({ state, commit, dispatch }, { camerasTableList, needCheckIsSelectAll = false }) {
      const selectedCameraList = await camerasWorker.send(camerasSelectSeveralTableStart({
        camerasTableList: camerasTableList,
        selectedCameraList: state.selectedCameraList,
        needCheckIsSelectAll
      }))
      commit('SET_SELECTED_CAMERA_LIST', selectedCameraList)
      if (needCheckIsSelectAll) {
        dispatch('setIsSelectAllTable')
      }
    },
    async uncheckSeveralCamerasTable({ state, commit }, camerasTableList) {
      const selectedCameraList = await camerasWorker.send(camerasUncheckSeveralTableStart({
        camerasTableList: camerasTableList,
        selectedCameraList: state.selectedCameraList
      }))
      commit('SET_SELECTED_CAMERA_LIST', selectedCameraList)
    },
    async setIsSelectAllTable({ state, rootState, commit }, selectAllValue) {
      /**
       * Если значение указано явно, то просто присваиваем его
       */
      if (selectAllValue !== undefined) {
        commit('SET_IS_SELECT_ALL_TABLE', selectAllValue)
        return
      }
      /**
       * Иначе функция выполняет проверку, все ли в таблице выделены сейчас
       */
      const camerasTableList = rootState.cameras.camerasTableList
      const isSelectedAll = await camerasWorker.send(camerasGetIsSelectAllStart({
        camerasTableList: camerasTableList,
        selectedCameras: state.selectedCameraList.cameras
      }))
      commit('SET_IS_SELECT_ALL_TABLE', isSelectedAll)
    },
    resetSelectedCameraListAction({commit, dispatch}) {
      commit('RESET_SELECTED_CAMERA_LIST')
      dispatch('setIsSelectAllTable', false)
    }
  }
}