import Vue from 'vue'
import http from '@/services/http'
import { sortCams, getPaginateCams } from '@/filter-cams-utils'
import camerasWorker from '@/worker'
import { camerasFilterStart,
         camerasFindIndexStart,
         camerasGetCamerasAllHashStart
      } from '@/worker/worker-actions'
import { pageSize } from '@/consts/table'
import { requestTimeout } from '@/consts/timeouts'
import { getRequestWithCancel, joinIds } from './utils'


const getDefaultFilters = () => ({
  statusCameras: {},
  rangeOfIpCameras: '',
  rangeOfIpServer: {},
  status_exploitation: {},
  q: ''
})

const getDefaultPagination = () => ({
  page: 1,
  limit: pageSize,
})

const getDefaultSortParams = () => ({
  field: 'name',
  type: 'asc'
})

const getDefaultState = () => {
  return {
    camerasAllHash: {},
    camerasTableList: [],
    camerasTablePageList: [],
    filters: getDefaultFilters(),
    sortParams: getDefaultSortParams(),
    pagination: getDefaultPagination(),
    tableLoading: false,
    statistic: {},
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },

    SET_CAMERAS_ALL_HASH(state, camerasAllHash) {
      state.camerasAllHash = camerasAllHash
    },

    SET_CAMERAS_TABLE_LIST(state, camerasTableList) {
      state.camerasTableList = camerasTableList
    },

    SET_CAMERAS_TABLE_PAGE_LIST(state, camerasTablePageList) {
      state.camerasTablePageList = camerasTablePageList
    },

    SET_SORT_PARAMS: (state, sortParams) => {
      state.sortParams = sortParams
      sortCams(state.camerasTableList, state.sortParams)
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },
    
    SET_DEFAULT_PAGINATION: (state) => {
      state.pagination = getDefaultPagination()
    },

   /**
     * 
     * @param {string[]} savedFields 
     */
    SET_DEFAULT_FILTERS: (state, savedFields) => {
      if (!savedFields || !savedFields.length) {
        state.filters = getDefaultFilters()
        return
      }
      const defaultFilters = getDefaultFilters()
      savedFields.forEach(field => {
        delete defaultFilters[field]
      })
      state.filters = Object.assign({}, state.filters, defaultFilters)
    },

    SET_FILTER: (state, { key, value }) => {
      state.filters[key] = value
    },

    SET_TABLE_LOADING: (state, tableLoading) => {
      state.tableLoading = tableLoading
    },

    SET_STATISTIC: (state, statistic) => {
      state.statistic = statistic
    },

    UPDATE_SINGLE_CAMERA: (state, { indexCam, dataCam }) => {
      if (state.camerasAllHash[dataCam.id] !== undefined) {
        const oldStatus = state.camerasAllHash[dataCam.id].status
        if (oldStatus !== dataCam.status) {
          if (state.statistic[oldStatus]) {
            state.statistic[oldStatus] -= 1
          }
          if (state.statistic[dataCam.status] === undefined) {
            state.statistic[dataCam.status] = 1
          } else {
            state.statistic[dataCam.status] += 1
          }
        }
        Vue.set(state.camerasAllHash, dataCam.id, dataCam)
      }
      
      const updateCameraInList = (list, indexCam) => {
        if (indexCam !== -1 && list[indexCam] !== undefined) {
          list.splice(indexCam, 1, dataCam) 
        }
      }

      updateCameraInList(state.camerasTableList, indexCam)

      const pageListIndex = state.camerasTablePageList.findIndex(camItem => camItem.id === dataCam.id)

      updateCameraInList(state.camerasTablePageList, pageListIndex)


    },

    REMOVE_SINGLE_CAMERA: (state, { indexCam, dataCam }) => {
      const removeCameraInList = (list, indexCam) => {
        if (indexCam !== -1 && list[indexCam] !== undefined) {
          Vue.delete(list, indexCam) 
        }
      }

      removeCameraInList(state.camerasTableList, indexCam)

      const pageListIndex = state.camerasTablePageList.findIndex(camItem => camItem.id === dataCam.id)

      removeCameraInList(state.camerasTablePageList, pageListIndex)

      if (state.camerasAllHash[dataCam.id] !== undefined) {
        Vue.delete(state.camerasAllHash, dataCam.id)
      }

      if (state.statistic[dataCam.status]) {
        state.statistic[dataCam.status] -= 1
      }
    }
  },
  actions: {
    async getCamerasAll({ commit, dispatch, rootState }) {
      const selectedCameraList = rootState.selectedCamerasTable.selectedCameraList
      commit('SET_TABLE_LOADING', true)
      const res = await http.get('cameras/all')
      const payload = await camerasWorker.send(camerasGetCamerasAllHashStart({
        camerasList: res.list,
        selectedCameraList
      }))
      commit('SET_CAMERAS_ALL_HASH', payload.camerasAllHash)
      commit('SET_STATISTIC', payload.statistic)
      commit('selectedCamerasTable/SET_SELECTED_CAMERA_LIST', payload.selectedCameraList, {root: true})
      dispatch('getFilteredCamerasTable')
    },

    async getFilteredCamerasTable({ state, commit, dispatch }) {
      const { filters, camerasAllHash, sortParams } = state
      commit('SET_TABLE_LOADING', true)
      const filteredCams = await camerasWorker.send(camerasFilterStart({
        filters,
        camerasAllHash,
        sortParams
      }))
      commit('SET_CAMERAS_TABLE_LIST', filteredCams)
      await dispatch('paginateCamerasTable')
      commit('SET_TABLE_LOADING', false)
    },

    sortCamerasTable({ state, commit, dispatch }, sort = {field: 'name', type: 'asc'}) {
      commit('SET_SORT_PARAMS', sort)
      dispatch('paginateCamerasTable', state.pagination)
    },

    paginateCamerasTable({ commit, state }, pagination) {
      let paginationApply = pagination ? pagination : state.pagination
      const { pageList, page } = getPaginateCams(state.camerasTableList, paginationApply )
      commit('SET_CAMERAS_TABLE_PAGE_LIST', pageList)
      commit('SET_PAGINATION', {
        ...state.pagination,
        page
      })
    },

    async applyFilters({ state, dispatch }) {
      await dispatch('getFilteredCamerasTable')
      await dispatch('paginateCamerasTable', {
        ...state.pagination,
        page: 1
      })
    },

    getIpServer() {
      return http.get('cameras/ip-server')
      .then(res => {
        return res.list.map(ip => {
          return {
            id: ip,
            name: ip
          }
        })
      })
      .catch(() => {})
    },
    async getNotVerifiedCameras(store, addParams) {
      const queryParams = {
        ...addParams
      }
      return getRequestWithCancel('unverified/passport', queryParams)
    },
    async createUpdatePassportsGroup({ rootState }, { data }) {
      const selectedCamerasIds = Object.keys(rootState.selectedCamerasTable.selectedCameraList.cameras)
      const reqData = {
        stream: {
          ...data
        },
        ids: joinIds(selectedCamerasIds)
      }
      const res = await http.post('passports', reqData, { timeout: requestTimeout })
      return res
    },
    async groupCamerasStatistic({ rootState }) {
      const selectedCamerasIds = Object.keys(rootState.selectedCamerasTable.selectedCameraList.cameras)
      const res = await http.post('selected/cameras/status',
        {
          ids: joinIds(selectedCamerasIds)
        },
        {
          timeout: requestTimeout
        }
      )
      return res
    },
    async updateSingleCamera({ state, commit }, dataCam) {
      if (state.camerasAllHash[dataCam.id]) {
        commit('selectedCamerasTable/CHANGE_SELECTED_CAMERA_STATUS_EXP', {
          camItem: dataCam,
          oldStatusExp: state.camerasAllHash[dataCam.id].status_exploitation
        }, { root: true })
      }
      
      const indexCam = await camerasWorker.send(camerasFindIndexStart({
        camerasTableList: state.camerasTableList,
        dataCam
      }))

      commit('UPDATE_SINGLE_CAMERA', { indexCam, dataCam })
    },
    async removeSingleCamera({ state, commit, dispatch }, dataCam) {
      if (state.camerasAllHash[dataCam.id]) {
        commit('selectedCamerasTable/REMOVE_SELECTED_CAMERA', state.camerasAllHash[dataCam.id], { root: true })
      }
      const indexCam = await camerasWorker.send(camerasFindIndexStart({
        camerasTableList: state.camerasTableList,
        dataCam
      }))
      
      commit('REMOVE_SINGLE_CAMERA', { indexCam, dataCam })
      dispatch('paginateCamerasTable')
    },
    async createUpdatePassport({ dispatch }, { data: { id, data }, isCreate = true }) {
      const params = {
        stream: {
          ...data
        }
      }
      let res
      if (isCreate) {
        res = await http.post(`camera/passport/${encodeURIComponent(id)}`, params)
      } else {
        res = await http.put(`camera/passport/${encodeURIComponent(id)}`, params)
      }
      await dispatch('updateSingleCamera', res)
      return res
    },
    async inOutExploitation({ dispatch }, { status_exploitation, id }) {
      let res
      switch (status_exploitation) {
        case 'notVerified':
          /**
           * ввести в эксплуатацию
           */
          res = await http.patch(`camera/passport/${encodeURIComponent(id)}`)
          break
        case 'introduced':
          /**
           * вывести из эксплуатаци
           */
          res = await http.delete(`camera/passport/${encodeURIComponent(id)}`)
          break
        default:
          return
      }
      await dispatch('updateSingleCamera', res)
      return res
    },
    async updateCameraInfo({ dispatch }, id) {
      const res = await http.get(`camera/passport/${encodeURIComponent(id)}`)
      await dispatch('updateSingleCamera', res)
      return res
    },
    async getCameraImage(store, id) {
      let res = ''
      const data = await http.get(`camera/passport/${encodeURIComponent(id)}/image`)
      if (data.image) {
        res = data.image
      }
      return res
    }
  }
}