import { mapState } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import DoughnutChart from '../doughnut-chart'
import { CAMERA_STATUSES, cameraStatusList } from '@/consts/statuses'
import { useSetStatusCameras } from '../../composition/useSetStatusCameras'
import { useClearAllFilters } from '../../composition/useClearAllFilters'

export default defineComponent({
  name: 'TableDataChart',
  components: {
    DoughnutChart
  },
  setup(props, context) {
    const { setStatus } = useSetStatusCameras(context.root.$store)
    const { clearAllFilters } = useClearAllFilters(context.root.$store)

    return {
      cameraStatuses: CAMERA_STATUSES,
      cameraStatusList,
      setStatus,
      clearAllFilters
    }
  },
  data() {
    return {
      chartHover: false
    }
  },
  computed: {
    ...mapState({
      loading: state => state.cameras.loading,
      statistic: state => state.cameras.statistic,
      camerasAllHash: state => state.cameras.camerasAllHash
    }),
    totalCameras() {
      return Object.keys(this.camerasAllHash).length
    },
    chartData() {
      return CAMERA_STATUSES
      .map(status => {
        return this.statistic[status] !== undefined ? this.statistic[status] : 0
      })
    },
    chartLegend() {
      return this.cameraStatusList.map(statusObj => {
        return {
          status: statusObj.id,
          total: this.statistic[statusObj.id] !== undefined ? this.statistic[statusObj.id] : 0,
          statusFilterItem: statusObj
        }
      }).reverse()
    },
  },
  methods: {
    onChartClick(event) {
      if (event && !this.loading) {
        const status = this.cameraStatusList[event._index]
        this.setStatus(status)
      }
    },
    onMouseChartEnter() {
      this.chartHover = true
    },
    onMouseChartOut() {
      this.chartHover = false
    }
  }
})