import { defineComponent, ref, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'Modal',
  props: {
    rightAlign: {
      type: Boolean,
      default: false
    },
    closeManual: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const isOpen = ref(false)
    let adaptiveQl = null
    const fullScreen = ref(false)

    const onAdaptiveChange = () => {
      fullScreen.value = adaptiveQl.matches
    }

    onMounted(() => {
      if (props.fullScreenWidth) {
        adaptiveQl = window.matchMedia(`(max-width: ${this.fullScreenWidth}px)`)
        adaptiveQl.addEventListener('change', onAdaptiveChange)
        this.onAdaptiveChange()
      }
    })

    onBeforeUnmount(() => {
      if (adaptiveQl) {
        adaptiveQl.removeEventListener('change', onAdaptiveChange)
      }
    })

    const show = () => {
      isOpen.value = true
      const appEl = document.getElementById('app')
      appEl.classList.add('has-modal')
    }

    const hide = () => {
      context.emit('close')
      isOpen.value = false
      context.root.$nextTick(() => {
        const modalContainer = document.getElementById('modal-container')
        const modalOverlayEls = modalContainer.getElementsByClassName('modal-overlay')
        if (modalOverlayEls.length === 0) {
          const appEl = document.getElementById('app')
          appEl.classList.remove('has-modal')
        }
      })
    }

    const onClickOverlay = () => {
      if (props.closeManual) {
        context.emit('click-overlay')
      } else {
        hide()
      }
    }

    return {
      isOpen,
      adaptiveQl,
      fullScreen,
      show,
      hide,
      onClickOverlay
    }
  },
  computed: {
    ...mapState({
      errorConnection: state => state.errorConnection
    })
  },
  watch: {
    errorConnection() {
      if (this.errorConnection) {
        this.hide()
      }
    }
  }
})