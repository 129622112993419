import { WorkerTypes } from './worker-types'

export const camerasFilterStart = ({ filters,
  camerasAllHash,
  sortParams
}) => ({
  type: WorkerTypes.CAMERAS_FILTER_START,
  payload: { filters,
    camerasAllHash,
    sortParams
  }
})

export const camerasSelectSeveralTableStart = ({
  camerasTableList,
  selectedCameraList,
  needCheckIsSelectAll
}) => ({
  type: WorkerTypes.CAMERAS_SELECT_SEVERAL_TABLE_START,
  payload: {
    camerasTableList,
    selectedCameraList,
    needCheckIsSelectAll
  }
})

export const camerasUncheckSeveralTableStart = ({ camerasTableList, selectedCameraList }) => ({
  type: WorkerTypes.CAMERAS_UNCHECK_SEVERAL_TABLE_START,
  payload: {
    camerasTableList,
    selectedCameraList
  }
})

export const camerasGetIsSelectAllStart = ({ camerasTableList, selectedCameras  }) => ({
  type: WorkerTypes.CAMERAS_GET_IS_SELECT_ALL_START,
  payload: {
    camerasTableList,
    selectedCameras
  }
})

export const camerasFindIndexStart = ({ camerasTableList, dataCam }) => ({
  type: WorkerTypes.CAMERAS_FIND_INDEX_START,
  payload: {
    camerasTableList,
    dataCam
  }
})

export const camerasGetCamerasAllHashStart = ( { camerasList, selectedCameraList } ) => ({
  type: WorkerTypes.CAMERAS_GET_CAMERAS_ALL_HASH_START,
  payload: { camerasList, selectedCameraList }
})

export const reportsGetOptionsAllStart = ({
  camerasAllHash,
  selectedCameras
}) => ({
  type: WorkerTypes.REPORTS_GET_CAMERAS_OPTIONS_ALL_START,
  payload: {
    camerasAllHash,
    selectedCameras
  }
})

export const reportsGetCamerasOptionsFilteredStart = ({
  camsOptionsAll,
  query
}) => ({
  type: WorkerTypes.REPORTS_GET_CAMERAS_OPTIONS_FILTERED_START,
  payload: {
    camsOptionsAll,
    query
  }
})

export const reportsCheckSelectedAllStart = ({
  camsOptionsFiltered,
  selectedCameras
}) => ({
  type: WorkerTypes.REPORTS_CHECK_IS_SELECTED_ALL_START,
  payload: {
    camsOptionsFiltered,
    selectedCameras
  }
})

export const reportSelectAllStart = ({
  camsOptionsFiltered,
  selectedCameras
}) => ({
  type: WorkerTypes.REPORTS_SELECT_ALL_START,
  payload: {
    camsOptionsFiltered,
    selectedCameras
  }
})

export const reportUncheckAllStart = ({
  camsOptionsFiltered,
  selectedCameras
}) => ({
  type: WorkerTypes.REPORTS_UNCHECK_ALL_START,
  payload: {
    camsOptionsFiltered,
    selectedCameras
  }
})
