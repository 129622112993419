import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import EditCameras from '../../components/edit-cameras/edit-cameras.vue'

export default defineComponent({
  name: 'TableActions',

  components: {
    EditCameras
  },
  
  computed: {
    ...mapState({
      updateLoading: state => state.update.loading,
      camerasTableList: state => state.cameras.camerasTableList,
      selectedCameraList: state => state.selectedCamerasTable.selectedCameraList,
      isSelectAllTable: state => state.selectedCamerasTable.isSelectAllTable
    }),

    ...mapGetters({
      selectedTableCameraCount: 'selectedCamerasTable/selectedTableCameraCount'
    }),

    disableCreateReport() {
      return !this.selectedTableCameraCount || this.updateLoading
    }
  },

  methods: {
    ...mapActions({
      updateCameras: 'update/updateCameras',
      selectSeveralCamerasTable: 'selectedCamerasTable/selectSeveralCamerasTable',
      uncheckSeveralCamerasTable: 'selectedCamerasTable/uncheckSeveralCamerasTable',
      setIsSelectAllTable: 'selectedCamerasTable/setIsSelectAllTable'
    }),
    ...mapMutations({
      setFiltersReports: 'reports/SET_FILTER_REPORTS',
      resetFitlersReports: 'reports/RESET_FILTERS_REPORTS'
    }),

    selectAllCamerasTable() {
      this.selectSeveralCamerasTable({ camerasTableList: this.camerasTableList })
      this.setIsSelectAllTable(true)
    },

    uncheckAllCamerasTable() {
      this.uncheckSeveralCamerasTable(this.camerasTableList)
      this.setIsSelectAllTable(false)
    },

    onSelectAll(event) {
      event ? this.selectAllCamerasTable() : this.uncheckAllCamerasTable()
    },

    changeReportsFilters() {
      this.resetFitlersReports()
      this.setFiltersReports({ key: 'selectedCameras', value: { ...this.selectedCameraList.cameras } })
    }
  }
})
