/**
 * для списков в статистике, либо в опциях
 */
export const CAMERA_STATUSES = ['ok', 'problem', 'notworking', 'unknown']
export const EXPLOITATION_STATUSES = ['introduced', 'notVerified', 'notFilled']

export const cameraStatusList = CAMERA_STATUSES.map(s => ({
  id: s,
  name: `status.camera.${s}`
}))

export const expoitationStatusList = EXPLOITATION_STATUSES.map(s => ({
  id: s,
  name: `status.exploitation.${s}`
}))