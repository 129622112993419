var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cameras-table"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.camerasTableList.length || _vm.tableLoading),expression:"camerasTableList.length || tableLoading"}],staticClass:"table-scrollbar-wrapper"},[(_vm.tableLoading)?_c('loader',{staticClass:"table-loader"}):_vm._e(),_c('v-table',{ref:"table",attrs:{"mode":"remote","fixed-header":"","sort-options":{
        enabled: true,
        initialSortBy: _vm.sortParams
      },"totalRows":_vm.camerasTableList.length,"rows":_vm.camerasTablePageList,"columns":_vm.tableColumns,"styleClass":"vgt-table"},on:{"on-sort-change":_vm.onSortChange,"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-column",fn:function(ref){
      var column = ref.column;
return [(column.field === 'select-row')?_c('v-checkbox',{attrs:{"value":_vm.isSelectedCurrentPage},on:{"input":_vm.onSelectCurrentPage}}):[_c('div',{staticClass:"border-separate"}),_vm._v(" "+_vm._s(_vm.$t(("cameraPrams." + (column.field))))+" ")]]}},{key:"table-row",fn:function(ref){
      var column = ref.column;
      var row = ref.row;
      var formattedRow = ref.formattedRow;
return [(column.field === 'select-row')?_c('v-checkbox',{attrs:{"value":_vm.selectedCameraList.cameras[row.id] !== undefined,"stop-propagation":""},on:{"input":function($event){return _vm.onSelectCamera($event, row)}}}):_vm._e(),(column.field === 'name')?_c('div',{staticClass:"status-name",attrs:{"title":formattedRow[column.field]}},[_c('base-icon',{staticClass:"status-name__icon-type",attrs:{"name":("cameras/ic-cam-" + (row.type))}}),_c('div',{staticClass:"status-name__text"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])],1):(column.field === 'status')?_c('div',{staticClass:"status-wrapper"},[_c('div',{class:("status-camera status-camera--" + (row.status))},[_vm._v(" "+_vm._s(_vm.$t(("status.camera." + (row.status))))+" ")])]):(column.field === 'status_exploitation')?_c('div',{staticClass:"status-wrapper"},[_c('div',{class:("status-exploitation status-exploitation--" + (row.status_exploitation))},[(row.update_time)?[_vm._v(" "+_vm._s(_vm.$tc(("status.exploitation." + (row.status_exploitation)), 1, { date: _vm.getStatusDate(row.update_time) }))+" ")]:[_vm._v(" "+_vm._s(_vm.$tc(("status.exploitation." + (row.status_exploitation)), 0))+" ")]],2)]):[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")]]}},{key:"emptystate",fn:function(){return [_c('div',{staticClass:"table-loading-text"},[_vm._v(" "+_vm._s(_vm.$t('table.emptyState'))+" ")])]},proxy:true}])}),_c('custom-pagination',{attrs:{"value":_vm.pagination.page,"page-count":_vm.pageTotal,"click-handler":_vm.onClickPage}})],1),_c('stub',{directives:[{name:"show",rawName:"v-show",value:(_vm.camerasTableList.length === 0 && !_vm.tableLoading && !_vm.updateLoading),expression:"camerasTableList.length === 0 && !tableLoading && !updateLoading"}],attrs:{"type":"emptySearch"}}),_c('passport-modal',{ref:"passport-modal",attrs:{"camera":_vm.cameraEdit},on:{"close":_vm.onClosePassportModal,"error-update":_vm.onErrorUpdateCamera}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }