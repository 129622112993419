import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ReportCamerasSelect from '../report-cameras-select/report-cameras-select.vue'
import SelectInterval from '@/components/select-interval/select-interval.vue'
import { blurForm } from '@/components/composition/blur-elements'

export default defineComponent({
  name: 'ReportsFilterForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    ReportCamerasSelect,
    SelectInterval
  },

  computed: {
    ...mapState({
      loading: state => state.reports.loading,
      filtersReports: state => state.reports.filtersReports
    }),

  },

  methods: {
    ...mapActions({
      getCreateReport: 'reports/getCreateReport',
      stopReport: 'reports/stopReport'
    }),

    ...mapMutations({
      setIsFromMainReport: 'reports/SET_IS_FROM_MAIN_REPORT',
      setFilterReports: 'reports/SET_FILTER_REPORTS',
      toggleIdMainFilters: 'selectCamerasReport/TOGGLE_ID_MAIN_FILTERS',
      setLoading: 'reports/SET_LOADING',
    }),

    onFilterSubmit(e) {
      blurForm(e.target)
      this.$refs['filter-form'].validate().then(async result => {
        if (result) {
          await this.getCreateReport()
        }
      })
    },

    setRangeOfDate(event) {
      this.setFilterReports({key: 'rangeOfDate', value: event})
    },

    stopCreateReport() {
      this.stopReport()
      this.setLoading(false)
    },
  },
  watch: {
    '$i18n.locale'() {
      if (this.$refs['filter-form'].flags.validated) {
        this.$refs['filter-form'].validate()
      }
    }
  }
})
