import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'LoaderModal',
	methods: {
		show () {
			this.$refs.modal.show()
		},
		hide () {
			this.$refs.modal.hide()
		}
	}
})

