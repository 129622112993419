import Vue from 'vue'
import http from '@/services/http'
import moment from 'moment-timezone'
import { useI18n } from 'vue-i18n-composable/dist/index.js'
import axios from 'axios'
import { filedsChart } from '@/consts/report-charts'
import { getRequestWithCancel, joinIds } from './utils'
import camerasWorker from '@/worker'
import {
  reportsGetOptionsAllStart,
  reportsGetCamerasOptionsFilteredStart,
  reportsCheckSelectedAllStart,
  reportSelectAllStart,
  reportUncheckAllStart
} from '@/worker/worker-actions'

const formatDateParams = 'YYYYMMDDTHHmmss'

const formatDateRange = ([startObj, endObj]) => {
  const startCorrect = startObj.clone().startOf('minute').format(formatDateParams)
  const endCorrect = endObj.clone().subtract(1, 'minute').set('second', 59).format(formatDateParams)
  return `${startCorrect}-${endCorrect}`
}

class DefaultFilters {
  constructor() {
    this.rangeOfDate = null
    /**
     * src/store/add-remove-selected-utils.js - getSelectedTablCam
     */
    this.selectedCameras = {}
  }
}

const getDefaultState = () => {
  const result = {

    camsOptionsAll: [],
    camsOptionsFiltered: [],
    /**
     * Выбраны ли все отфильтрованные камеры
     */
    isSelectedAll: false,

    filtersReports: new DefaultFilters(),
    /**
   * dataReport по запросу /report имеет следующую структуру
   * {
   *   // intervals - массив объектов { start: String, end: String, value: string }
   *   // timeUnit - по спеке https://www.chartjs.org/docs/latest/axes/cartesian/time.html
   *   workingTime: {
   *     intervals: [],
   *     // часы:минуты
   *     totalValue: '0:0',
   *     timeUnit: 'week'
   *   },
   *   workingTimePercent: {
   *     intervals: [],
   *     // отличие от времени в разделителе
   *     totalValue: '0.0',
   *     timeUnit: 'week'
   *   },
   *   problemTime: {
   *     intervals: [],
   *     // часы:минуты
   *     totalValue: '0:0',
   *     timeUnit: 'week'
   *   },
   *   notWorkingCameraCount: 0,
   *   totalTime: '0:0'
   * }
   */
    dataReport: null,
    camerasReportList: [],
    createdRangeOfDate: null,
    loading: false,
    exportLoading: false,
    reportCharts: {}
  }

  filedsChart.forEach(fieldObj => {
    result.reportCharts[fieldObj.field] = {
      dataChart: {
        intervals: [],
        timeUnit: '',
        totalValue: ''
      },
      /**
       * массив объектов { start, end }
       */
      history: []  
    }
  })
  return result
}

let filterReportCreated = null

const cancelReqs = {
  report: null,
  export: null
}

export default {
  namespaced: true,

  state: getDefaultState(),
  
  mutations: {
    SET_CAMS_OPTIONS_ALL(state, camsOptionsAll) {
      state.camsOptionsAll = camsOptionsAll
    },
    SET_CAMS_OPTIONS_FILTERED(state, camsOptionsFiltered) {
      state.camsOptionsFiltered = camsOptionsFiltered
    },
    SET_IS_SELETED_ALL(state, isSelectedAll) {
      state.isSelectedAll = isSelectedAll
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    SET_FILTER_REPORTS(state, { key, value }) {
      Vue.set(state.filtersReports, key, value)
    },
    RESET_FILTERS_REPORTS(state) {
      state.filtersReports = new DefaultFilters()
    },

    SET_DATA_REPORT(state, dataReport) {
      state.dataReport = dataReport
    },
    SET_CAMERAS_REPORT_LIST(state, camerasReportList) {
      state.camerasReportList = camerasReportList
    },
    SET_CREATED_RANGE_OF_DATE(state, createdRangeOfDate) {
      state.createdRangeOfDate = createdRangeOfDate
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_REPORT_DATA_CHART(state, { field, dataChart }) {
      state.reportCharts[field].dataChart = dataChart
    },
    SET_REPORT_CHART_HISTORY(state, { field, history }) {
      state.reportCharts[field].history = history
    },
    SET_EXPORT_LOADING(state, exportLoading) {
      state.exportLoading = exportLoading
    }
  },
  actions: {
    async getCamsOptionsAll({ rootState, state, commit }) {
      const camerasAllHash = rootState.cameras.camerasAllHash
      const selectedCameras = state.filtersReports.selectedCameras
      const payload =  await camerasWorker.send(reportsGetOptionsAllStart({
        camerasAllHash,
        selectedCameras
      }))
      commit('SET_IS_SELETED_ALL', payload.isSelectedAll)
      commit('SET_CAMS_OPTIONS_ALL', payload.camsOptionsAll)
      commit('SET_CAMS_OPTIONS_FILTERED', payload.camsOptionsAll)
      commit('SET_FILTER_REPORTS', { key:'selectedCameras', value: payload.selectedCameras })
    },
    async getCamsOptionsFiltered({ state, commit }, query) {
      const camsOptionsAll = state.camsOptionsAll
      const camsOptionsFiltered = await camerasWorker.send(reportsGetCamerasOptionsFilteredStart({
        query,
        camsOptionsAll
      }))
      commit('SET_CAMS_OPTIONS_FILTERED', camsOptionsFiltered)
    },
    /**
     * проверка, выбраны ли все отфильтрованные сейчас
     */
    async checkIsSelectAll({ state, commit }) {
      const camsOptionsFiltered = state.camsOptionsFiltered
      const selectedCameras = state.filtersReports.selectedCameras
      const isSelectedAll = await camerasWorker.send(reportsCheckSelectedAllStart({
        camsOptionsFiltered,
        selectedCameras
      }))
      commit('SET_IS_SELETED_ALL', isSelectedAll)
    },
    /**
     * Выбрать все отфильтрованные
     */
    async selectAll({ state, commit }) {
      const camsOptionsFiltered = state.camsOptionsFiltered
      const selectedCameras = state.filtersReports.selectedCameras
      const payloadSelectedCameras = await camerasWorker.send(reportSelectAllStart({
        camsOptionsFiltered,
        selectedCameras
      }))
      commit('SET_IS_SELETED_ALL', true)
      commit('SET_FILTER_REPORTS', { key:'selectedCameras', value: payloadSelectedCameras })
    },
    /**
     * Снять все отфильтрованные
     */
    async uncheckAll({ state, commit }) {
      const camsOptionsFiltered = state.camsOptionsFiltered
      const selectedCameras = state.filtersReports.selectedCameras
      const payloadSelectedCameras = await camerasWorker.send(reportUncheckAllStart({
        camsOptionsFiltered,
        selectedCameras
      }))
      commit('SET_IS_SELETED_ALL', false)
      commit('SET_FILTER_REPORTS', { key:'selectedCameras', value: payloadSelectedCameras })
    },
    /**
     * chartPartParams: { fieldObj: { id: number, field: string } , chartInterval: { start: String, end: String } }
     */
    getOptionsRequestReport({ state, commit }, {
      chartPartParams,
      isSaveDateRange = false,
      isDetailCameras = false
    }) {
      const filters = state.filtersReports
      const localTimeZone = moment().utcOffset()

      const optionsRequest = {}

      optionsRequest.timezoneOffset = localTimeZone

      if (chartPartParams) {
        /**
         * Здесь строки, в отличии от filters.rangeOfDate,
         * в которых moment объекты
         */
        const { start, end } = chartPartParams.chartInterval
        const datesObjArr = [start, end].map(dateStr => moment(dateStr))
        optionsRequest.rangeOfDate = formatDateRange(datesObjArr)
        if (isSaveDateRange) {
          commit('SET_CREATED_RANGE_OF_DATE', {
            startDate: moment(start),
            endDate: moment(end)
          })
        }
        
        optionsRequest.metricsTag = chartPartParams.fieldObj.id

      } else if (filters.rangeOfDate) {
        const datesObjArr = [filters.rangeOfDate.startDate, filters.rangeOfDate.endDate]
        optionsRequest.rangeOfDate = formatDateRange(datesObjArr)
        if (isSaveDateRange) {
          commit('SET_CREATED_RANGE_OF_DATE', {
            startDate: filters.rangeOfDate.startDate.clone(),
            endDate: filters.rangeOfDate.endDate.clone()
          })
        }
      }

      if (!isDetailCameras) {
        const selectedIds = Object.keys(state.filtersReports.selectedCameras)
        if (selectedIds.length) {
          const idsStr = joinIds(selectedIds)
          optionsRequest.ids = idsStr
        }
      }

      return optionsRequest
    },

    async getCreateReport({ state, dispatch, commit }) {
      try {
        const optionsRequest = await dispatch('getOptionsRequestReport', { isSaveDateRange: true })
        
        let url = 'report'

        const cancelSource = axios.CancelToken.source()
        cancelReqs.report = cancelSource.cancel
        
        commit('SET_LOADING', true)
        const res = await http.post(url, optionsRequest, {
          cancelToken: cancelSource.token
        })

        commit('SET_DATA_REPORT', res)

        const filters = state.filtersReports

        filedsChart.forEach(fieldObj => {
          commit('SET_REPORT_DATA_CHART', {
            field: fieldObj.field,
            dataChart: res[fieldObj.field]
          })
          commit('SET_REPORT_CHART_HISTORY', {
            field: fieldObj.field,
            history: [{
              start: filters.rangeOfDate.startDate.clone().format(),
              end: filters.rangeOfDate.endDate.clone().format()
            }]
          })
        })

        filterReportCreated = optionsRequest

        const camerasReportsList = await http.get('cameras/reports/list')
        
        commit('SET_CAMERAS_REPORT_LIST', camerasReportsList.list)

        return res

      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getFileReport({ commit }, { type, isSeparate }) {

      const optionsRequest = { ...filterReportCreated }

      optionsRequest.locale = useI18n().locale.value
      optionsRequest.lang = navigator.language
      optionsRequest.isSeparate = isSeparate ? 1 : 0

      const url = 'report' + `/${type === 'xlsx' ? 'excel' : type}`

      try {
        const cancelSource = axios.CancelToken.source()
        cancelReqs.export = cancelSource.cancel
        commit('SET_EXPORT_LOADING', true)
        const res = await http.post(url, optionsRequest, {
          responseType: 'blob',
          cancelToken: cancelSource.token
        })
        const urlBlob = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = urlBlob
        const filename = res.filename.replace(/"/g, '')
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(urlBlob)
      } finally {
        commit('SET_EXPORT_LOADING', false)
      }
    },

    async getDetailReport({ state, dispatch, commit }, chartPartParams) {
      try {
        commit('SET_GLOBAL_LOADING', true, { root: true })
        const optionsRequest = await dispatch('getOptionsRequestReport', { chartPartParams })
        const res = await http.post('reports/detail', optionsRequest)

        commit('SET_REPORT_DATA_CHART', {
          field: chartPartParams.fieldObj.field,
          dataChart: res
        })

        let history = state.reportCharts[chartPartParams.fieldObj.field].history
        const addedItemHistory = chartPartParams.chartInterval
        const indexHistory = history.findIndex(item => {
          return item.start === addedItemHistory.start && item.end === addedItemHistory.end
        })
        if (indexHistory !== -1) {
          history = history.slice(0, indexHistory + 1)
        } else {
          history.push(addedItemHistory)
        }
        commit('SET_REPORT_CHART_HISTORY', {
          field: chartPartParams.fieldObj.field,
          history
        })
      } finally {
        commit('SET_GLOBAL_LOADING', false, { root: true })
      }
    },

    async getDetailOptionsRequest({ state, dispatch }, fieldObj) {
      const history = state.reportCharts[fieldObj.field].history
      const lastHistoryStep = history[history.length - 1]

      const chartPartParams = {
        fieldObj,
        chartInterval: lastHistoryStep
      }
      const optionsRequest = await dispatch('getOptionsRequestReport', { chartPartParams, isDetailCameras: true })
      return optionsRequest
    },

    async updateDetailCameras({ commit, dispatch }, fieldObj) {
      
      try {
        commit('SET_GLOBAL_LOADING', true, { root: true })
        const optionsRequest = await dispatch('getDetailOptionsRequest', fieldObj)
        await http.get('reports/detail/cameras/update', { params: optionsRequest })
      } finally {
        commit('SET_GLOBAL_LOADING', false, { root: true })
      }
    },

    getDetailCameraList(store, params) {
      const { req, cancelToken } = getRequestWithCancel('reports/detail/cameras/list', params)
      return {
        req,
        cancelToken
      }
    },

    async getChartCameraDetail({ dispatch }, { fieldObj, detailCameraId }) {
      const optionsRequest = await dispatch('getDetailOptionsRequest', fieldObj)
      optionsRequest.detailCameraId = detailCameraId
      return http.post('reports/detail', optionsRequest)
    },

    stopReport() {
      if (cancelReqs.report) {
        cancelReqs.report()
        cancelReqs.report = null
      }
    },

    stopExportReport() {
      if (cancelReqs.export) {
        cancelReqs.export()
        cancelReqs.export = null
      }
    }
  }
}