import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LineScale',
  props: {
    value: {
      /**
       * от нуля до 100
       */
      type: Number,
      default: 0
    }
  }
  
})
