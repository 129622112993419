import PromiseWorker from 'promise-worker'

const worker = new Worker('./cameras-worker.js', { type: 'module' })

const promiseWorker = new PromiseWorker(worker)

const send = message => promiseWorker.postMessage({
  message
})

export default {
  promiseWorker,
  send
}
