var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-wrapper",class:{
    focus: _vm.focus,
    'has-error': _vm.error,
    'select-toggle': _vm.selectToggle
  }},[_c('div',{staticClass:"input-placeholder-wrapper"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"input",class:{
        'not-placeholder': !_vm.placeholder
      },attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"readonly":_vm.readonly || _vm.selectToggle,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange,"click":function($event){_vm.selectToggle ? _vm.$emit('click') : ''}}}),(_vm.placeholder)?_c('div',{staticClass:"placeholder-small"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()]),_c('div',{staticClass:"icons"},[(_vm.$slots.icon)?_c('div',{staticClass:"icon-wrapper",class:{
        'without-clear-icon': !_vm.clear || !_vm.value
      }},[_c(_vm.iconClickable ? 'button' : 'div',{tag:"component",staticClass:"input-icon",class:{
          clickable: _vm.iconClickable,
          
        },attrs:{"type":_vm.iconClickable ? 'button' : ''},on:{"click":_vm.onClickIcon}},[_vm._t("icon")],2)],1):_vm._e(),(_vm.clear && _vm.value)?_c('div',{staticClass:"icon-wrapper ic-clear-wrapper"},[_c('button',{staticClass:"input-icon clickable",attrs:{"type":"button"},on:{"click":_vm.onClear,"mouseover":function($event){_vm.isOnClearBtn = true},"mouseleave":function($event){_vm.isOnClearBtn = false}}},[_c('base-icon',{attrs:{"name":"ic-clear"}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }