import { defineComponent } from '@vue/composition-api'
import CameraNamesListWrapper from '@/components/camera-names-list-wrapper/camera-names-list-wrapper.vue'

export default defineComponent({
  name: 'EditCamerasResult',
  components: {
    CameraNamesListWrapper
  },
  props: {
    type: {
      type: String,
      default: 'passports' // passports | exploitation
    },
    res: {
      type: Object
    }
  },
  computed: {
    translateField() {
      const typeCapitalize = this.type.charAt(0).toUpperCase() + this.type.slice(1)

      return `edit${typeCapitalize}Results`
    },
    aggregatedData() {
      return {
        total: this.res.total,
        ignored: this.res.ignored,
        success: this.type === 'passports' ? this.res.update_success : this.res.activated,
        failed: this.type === 'passports' ? this.res.cameras_not_update : this.res.not_activated
      }
    }
  },
  methods: {
		show () {
			this.$refs.modal.show()
		},
		hide () {
			this.$refs.modal.hide()
		}
	}
})