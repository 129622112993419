var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.total),expression:"total"}],ref:"dropdown",staticClass:"not-verified-list",class:{
    open: _vm.open
  }},[_c('div',{staticClass:"not-verified-list__toggle",attrs:{"title":_vm.$t('header.notVerifiedList.tooltip')},on:{"click":_vm.toggleOpen}},[_vm._v(" "+_vm._s(_vm.total)+" ")]),_c('div',{staticClass:"not-verified-list__options"},[(_vm.currentOptions.length)?[_c('div',{staticClass:"scrollbar not-verified-list__options__content",on:{"scroll":_vm.onScrollEnd}},_vm._l((_vm.currentOptions),function(camera,index){return _c('div',{key:camera.id,staticClass:"not-verified-list__option",class:{
            last: index === _vm.currentOptions.length - 1
          }},[_c('div',{staticClass:"camera-name"},[_c('base-icon',{staticClass:"camera-name__icon",attrs:{"name":("cameras/ic-cam-" + (camera.type))}}),_c('div',{staticClass:"camera-name__text",attrs:{"title":camera.name}},[_vm._v(" "+_vm._s(camera.name)+" ")])],1),_c('div',{staticClass:"td-status-exploitation"},[_c('div',{class:"status-exploitation status-exploitation--notVerified"},[_vm._v(" "+_vm._s(_vm.$tc('status.exploitation.notVerified', 1, { date: _vm.getStatusDate(camera.update_time) }))+" ")])])])}),0),_c('div',{staticClass:"go-to-cameras__wrapper"},[_c('router-link',{attrs:{"custom":"","to":{
            name: 'main'
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
return [_c('a',{staticClass:"btn secondary go-to-cameras__btn",attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.onGoToCameras()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.goToCameras'))+" ")])]}}],null,false,998037936)})],1)]:_c('div',{staticClass:"empty-text"},[_vm._v(" "+_vm._s(_vm.$t('noCameras'))+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }