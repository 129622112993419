import { defineComponent } from '@vue/composition-api'

import TableDataChart from './components/table-data-chart/table-data-chart.vue'
import TableDataFilters from './components/table-data-filters/table-data-filters.vue'
import TableActions from './components/table-actions/table-actions.vue'
import CamerasTable from './components/cameras-table/cameras-table.vue'

export default defineComponent({
  name: 'CamerasTableModule',

  components: {
    TableDataChart,
    TableDataFilters,
    TableActions,
    CamerasTable
  },
})
