import moment from 'moment-timezone'
import { getFormatDatePattern } from '@/components/composition/format-utils.js'

export const useGetDateLabel = () => {
  const getDateLabel = (date, timeUnit, extreme = false) => {
    const timeFormat = 'HH:mm'
    if (timeUnit === 'hour') {
      return moment(date).format(timeFormat)
    }
    if (['day', 'week', 'month', 'quarter', 'year'].find(unit => unit === timeUnit)) {
      const dateStr = moment(date).format(getFormatDatePattern(false))
      if (extreme) {
        const timeStr = moment(date).format(timeFormat)
        return [ dateStr, timeStr ]
      }
      return dateStr
    }
    return moment(date).format(getFormatDatePattern(true))
  }

  return {
    getDateLabel
  }
}