import { defineComponent } from '@vue/composition-api'
import { mapState, mapMutations } from 'vuex'
import { formatNumber, getFormatDatePattern, statisticTimeFormat } from '@/components/composition/format-utils'
import ExportDropdown from '../export-dropdown/export-dropdown.vue'
import CameraNamesListWrapper from '@/components/camera-names-list-wrapper/camera-names-list-wrapper.vue'
import { fieldsStatisticCommon } from '@/consts/report-charts'

export default defineComponent({
  name: 'CommonReportInfo',

  components: {
    ExportDropdown,
    CameraNamesListWrapper
  },

  setup() {
    return {
      formatNumber,
      getFormatDatePattern,
      statisticTimeFormat,
      fieldsStatisticCommon
    }
  },

  computed: {
    ...mapState({
      dataReport: state => state.reports.dataReport,
      camerasReportList: state => {
        return state.reports.camerasReportList.map(cam => cam.name)
      },
    }),

    createdRangeOfDate() {
      const format = `${this.getFormatDatePattern()} (HH:mm)`
      const storedCreatedRangeOfDate = this.$store.state.reports.createdRangeOfDate
      if (storedCreatedRangeOfDate) {
        const { startDate, endDate } = storedCreatedRangeOfDate
        const startDateFormat = this.$moment(startDate).format(format)
        const endDateFormat = this.$moment(endDate).format(format)
        return `${startDateFormat} - ${endDateFormat}`
      }
      return ''
    },

    totalTimeView() {
      if (this.dataReport) {
        const result = this.statisticTimeFormat(this.dataReport.totalTime)
        result.hours = this.formatNumber(result.hours)
        return result
      }
    },
  },

  methods: {
    ...mapMutations({
      setLoading: 'reports/SET_LOADING',
    }),
  }
})