import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VToggle',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const onClick = () => {
      context.emit('input', !props.value)
    }
    return {
      onClick
    }
  }
})