import Inputmask from 'inputmask'

Inputmask.extendDefinitions({
  'i': {
    validator: '[\\da-z.:]'
  }
})

const inputmaskPlugin = {
  install: function(Vue) {
    Vue.directive('mask', {
      bind: function(el, binding) {
          Inputmask(binding.value).mask(el);
      }
    })
  }
}

export default inputmaskPlugin