import http from '@/services/http'
import axios from 'axios'

/**
 * 
 * @param {*} req - string | Promise
 * @param {*} queryParams 
 */
export const getRequestWithCancel = (url, queryParams = {}) => {
  const cancelSource = axios.CancelToken.source()
  const requestParams = {
    cancelToken: cancelSource.token
  }
  const queryParamsFiltered = { ...queryParams }
  const paramsKey = Object.keys(queryParamsFiltered)
  for (let i = 0; i < paramsKey.length; i++) {
    const key = paramsKey[i]
    if (!queryParamsFiltered[key]) {
      delete queryParamsFiltered[key]
    }
  }
  requestParams.params = queryParamsFiltered
  
  return {
    req: http.get(url, requestParams),
    cancelToken: cancelSource.cancel
  }
}

/**
 * 
 * @param {string[]} ids 
 */

export const joinIds = (ids) => {
  return ids.join(',')
}

/**
 * 
 * @param {{id: number | string}[]} ojbectArr 
 * @returns string
 */
export const getIdsStr = (ojbectArr) => {
  return joinIds(ojbectArr.map(o => o.id))
}