import { Validator } from 'ip-num/Validator'
import { IPv6, IPv4 } from "ip-num/IPNumber"
import { pageSize } from '@/consts/table'

export const getIpObj = (ipStr) => {
  const validResultV6 = Validator.isValidIPv6String(ipStr)
  if (validResultV6[0]) {
    return new IPv6(ipStr)
  }
  const validResultV4 = Validator.isValidIPv4String(ipStr)
  if (validResultV4[0]) {
    return new IPv4(ipStr)
  }
  return null
}

/**
 * 
 * @param {IPv6 | IPv4} left 
 * @param {IPv6 | IPv4} right 
 * @param {'isEquals' | 'isGreaterThanOrEquals' | 'isLessThanOrEquals' | 'isGreaterThan' | 'isLessThan'} operation 
 */
export const compareIp = (left, right, operation) => {
  if (
    (left instanceof IPv4 && right instanceof IPv4) ||
    (left instanceof IPv6 && right instanceof IPv6)
  ) {
    return left[operation](right)
  }
  if (left instanceof IPv4 && right instanceof IPv6) {
    return IPv6.fromIPv4(left)[operation](right)
  }
  return left[operation](IPv6.fromIPv4(right))
}


/**
 * 
 * @param {string} q 
 */

export const checkQ = (camItem, q) => {
  const qCorrect = q && q.trim().toLowerCase()
  if (qCorrect) {
    // были поля помимо name - 'status', 'ip_server', 'status_exploitation'
    const checkedFields = ['name']
    let result = false
    for (let i = 0; i < checkedFields.length; i++) {
      const fieldName = checkedFields[i]
      const fieldVal = camItem[fieldName]

      if (fieldVal) {
        const fieldValCorrect = fieldVal.toLowerCase()
        if (fieldValCorrect.indexOf(qCorrect) !== -1){
          result = true
          break
        }        
      }
    }
    return result
  }
  return true
}

/**
 * 
 * @param {{[id: string]: {id: string, name: string}}} statusCameras 
 */
const checkStatusCamera = (camItem, statusCameras) => {
  if (statusCameras && Object.keys(statusCameras).length) {
    return statusCameras[camItem.status] !== undefined
  }
  return true
}

/**
 * 
 * @param {{[id: string]: {id: string, name: string}}} status_exploitation
 */
 const checkStatusExploitation = (camItem, status_exploitation) => {
  if (status_exploitation && Object.keys(status_exploitation).length) {
    return status_exploitation[camItem.status_exploitation] !== undefined
  }
  return true
}

/**
 *
 * @param {string?} rangeOfIpCameras - например 172.217.22.23 - 172.217.22.23
 */
const checkRangeOfIpCameras = (camItem, rangeOfIpCameras) => {
  if (rangeOfIpCameras) {
    const correctItemIp = getIpObj(camItem.ip)
    if (correctItemIp === null) {
      return false
    }
    const rangeParts = rangeOfIpCameras.replace(/ /g, '')
      .split('-')
      .map(ipStr => getIpObj(ipStr))
      .filter(ipObj => ipObj !== null)
    
    if (!rangeParts.length) {
      return false
    }

    if (rangeParts.length === 1) {
      return compareIp(correctItemIp, rangeParts[0], 'isEquals')
    }

    return compareIp(correctItemIp, rangeParts[0], 'isGreaterThanOrEquals') &&
           compareIp(correctItemIp, rangeParts[1], 'isLessThanOrEquals')
  }
  return true
}

/**
 *
 * @param {{[id: string]: {id: string, name: string}}} rangeOfIpCameras
 */
const checkRangeOfIpServer = (camItem, rangeOfIpServer) => {
  if (rangeOfIpServer && Object.values(rangeOfIpServer).length) {
    const correctIpServer = getIpObj(camItem.ip_server)

    if (correctIpServer === null) {
      return false
    }

    return Object.values(rangeOfIpServer).find(ipServerRange => {
      const ipServerRangeObj = getIpObj(ipServerRange.id)
      if (ipServerRangeObj === null) {
        return false
      }
      return compareIp(ipServerRangeObj, correctIpServer, 'isEquals')
    }) !== undefined
  }

  return true
}

/**
 * 
 * @param {
 *  id: string
 *  fps: null
 *  height: null
 *  ip: "0.0.0.0"
 *  ip_server: "fe80::e13f:6d97:85d1:5dbe"
 *  kbps: null
 *  name: "DASHBOARD2: 7000"
 *  passport: null
 *  status: "notworking"
 *  status_exploitation: "notFilled"
 *  width: null
 * } camItem
 * @param {
 *  statusCameras?: [],
 *  rangeOfIpCameras?: '',
 *  rangeOfIpServer?: [],
 *  status_exploitation?: [],
 *  q?: ''
 * } filters 
 */


export const checkFilteredCamItem = (camItem, filters) => {
  const checkArr = [
    checkQ(camItem, filters.q),
    checkStatusCamera(camItem, filters.statusCameras),
    checkStatusExploitation(camItem, filters.status_exploitation),
    checkRangeOfIpCameras(camItem, filters.rangeOfIpCameras),
    checkRangeOfIpServer(camItem, filters.rangeOfIpServer)
  ]
  return checkArr.every(checkResult => checkResult)
}

const getNullableSortResult = (a, b, asc = true) => {
  if (a && !b) {
    return asc ? 1 : -1
  }
  if (!a && b) {
    return asc ? -1 : 1
  }
  if (!a && !b) {
    return 0
  }
  return undefined
}

export const sortCamsByParam = (aCam, bCam, sort = { field: 'id', type: 'asc' }) => {
  const { field, type } = sort
  const a = aCam[field]
  const b = bCam[field]
  const asc = type === 'asc'
  const nullCheck = getNullableSortResult(a, b, asc)
  if (nullCheck !== undefined) {
    return nullCheck
  }
  if (['ip', 'ip_server'].indexOf(field) !== -1) {
    const aIpObj = getIpObj(a)
    const bIpObj = getIpObj(b)
    const nullCheck = getNullableSortResult(aIpObj, bIpObj, asc)
    if (nullCheck !== undefined) {
      return nullCheck
    }
    if (compareIp(aIpObj, bIpObj, 'isGreaterThan')) {
      return asc ? 1 : -1
    }
    if (compareIp(aIpObj, bIpObj, 'isLessThan')) {
      return asc ? -1 : 1
    }
    return 0
  }

  if (a > b) {
    return asc ? 1 : -1
  }

  if (a < b) {
    return asc ? -1 : 1
  }

  return 0
}

export const getFilteredCams = (camsArr, filters) => {
  const filteredCams =  camsArr.filter(camItem => checkFilteredCamItem(camItem, filters))
  return filteredCams
}

export const sortCams = (camsArr, sort = { field: 'name', type: 'asc'}) => {
  return camsArr.sort((aCam, bCam) => {
    return sortCamsByParam(aCam, bCam, sort)
  })
}

export const getPagesCamsTotal = (camsArr, limit = pageSize) => {
  return Math.ceil(camsArr.length / limit)
}

export const getPaginateCams = (camsArr, pagination = { page: 1, limit: pageSize }) => {
  const { limit } = pagination
  let page = pagination.page
  const totalPages = getPagesCamsTotal(camsArr)
  if (totalPages === 0) {
    page = 1
  } else if (page > totalPages) {
    page = totalPages
  }
  const startIndex = ((page - 1) * limit)
  const endIndex = startIndex + limit
  return {
    pageList: camsArr.slice(startIndex, endIndex),
    page
  }
}
