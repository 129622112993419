import { defineComponent } from '@vue/composition-api'
import { restrictMaxValue } from '../composition/restrictMaxValue'


export default defineComponent({
  name: 'ResolutionInput',
  props: {
    value: {
      type: String | Number,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      required: true
    }
  },
  setup() {
    const getResolutionStr = (width, height) => {
      if (!width) {
        width = ''
      }
      if (!height) {
        height = ''
      }
      return `${width} : ${height}`
    }
    return {
      getResolutionStr,
      restrictMaxValue
    }
  },
  methods: {
    onInput(event) {
      const cursorPos = event.target.selectionStart
      const [ width, height ] = event.target.value
        .split(' : ')
        .map(item => this.restrictMaxValue(item.replace(/_/g, ''), this.limit))
      event.target.value = this.getResolutionStr(width, height)
      this.$emit('input', event.target.value)
      event.target.setSelectionRange(cursorPos, cursorPos)
    },
    checkCursorPosition(event) {
      event.target.setSelectionRange(0,0)
    },
    switchCursorPosition(event) {
      const inputValue = event.target.value
      const cursorPos = event.target.selectionStart
      const dividePos = inputValue.indexOf(':', cursorPos)

      if(dividePos !== -1) {
        event.preventDefault()
        event.target.setSelectionRange(dividePos + 2, dividePos + 2)
      }
      
    }
  }
})